import { defineStore } from 'pinia'
import Keycloak from "keycloak-js";
import axios from "axios";

export const wasticsStore = defineStore('wastics', {
  state: () => ({
    count: 0,
    business: null,
    token: null,
    payload: null,
    role: null,
    loggedIn: false,
    checkInterval: null,
    map: null,
    keycloak: null,
    user: {
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      registrationFinished: false,
    },
    users: [],
    usersMap: null,
  }),
  getters: {
    double: state => state.count * 2,
  },
  actions: {
    increment() {
      this.count++
    },
    isLoggedIn() {
      return this.loggedIn;
    },
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    parseJwt (token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    getToken() {
      return this.keycloak.token;
    },
    setToken(token) {
      this.token = token;
      this.payload = this.parseJwt(token);
      this.loggedIn = true;
      localStorage.setItem("wastics.token", this.token);
      localStorage.setItem("wastics.payload", JSON.stringify(this.payload));
    },
    clearToken() {
      this.token = null;
      this.payload = null;
      this.loggedIn = false;
      clearInterval(this.checkInterval);
      localStorage.removeItem("wastics.token");
      localStorage.removeItem("wastics.payload");
    },
    checkToken() {
      /*this.checkInterval = setInterval(() => {
        if (this.payload !== null) {
          if (this.payload.exp < new Date()/1000) {
            //todo renew token
            this.clearToken();
          }
        }
      }, 60000 * 5);*/

      let initOptions = {
        url: 'http://localhost:9000', realm: 'Wastics', clientId: 'prototype-app', onLoad:'login-required'
      }

      if (window.location.hostname !== "localhost") {
        initOptions = {
          url: 'https://auth.wastics.eu', realm: 'Wastics', clientId: 'prototype-app', onLoad:'login-required'
          //url: 'https://auth0.wastics.eu', realm: 'Wastics', clientId: 'prototype-app', onLoad:'login-required'
        }
      }

      this.keycloak = Keycloak(initOptions);

      this.keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
        if (!auth) {
          console.log("Authentication failed");
          window.location.reload();
        } else {
          console.log("Authenticated");
          if (process.env.NODE_ENV !== 'production') {
            console.log(this.keycloak.subject)
            console.log(this.keycloak.tokenParsed)
            console.log(this.keycloak.realmAccess)
            this.keycloak.realmAccess.roles.forEach(r => {
              console.log(r);
            })
            console.log(this.keycloak.resourceAccess)
          }
          axios
              .get(this.baseUrl() + "/v1/users/" + this.keycloak.subject, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
              .then((res) => {
                this.user = res.data;
                this.loggedIn = true;
                if(this.user.registrationFinished === false) {
                  this.$router.push('/welcome');
                } else {
                  axios.get(this.baseUrl() + "/v1/companies/" + this.user.business, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                      .then((res)=>{
                        this.business = res.data;
                      })
                }
              })
              .catch(()=>{
                console.log("Error fetching user data");
              });

          if(this.keycloak.realmAccess.roles.includes("wastics_admin")) {
            console.log("wastics admin fetching users")
            axios
                .get(this.baseUrl() + "/v1/users", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
                .then((res) => {
                  this.users = res.data;
                  if (process.env.NODE_ENV !== 'production') {
                    console.log(this.users);
                  }
                  this.usersMap = this.users.reduce(function(map, obj) {
                    map[obj.id] = obj;
                    return map;
                  }, {});
                })
                .catch(()=>{
                  console.log("Error fetching users");
                });
          }
        }

        //Token Refresh
        setInterval(() => {
          this.keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
              if (process.env.NODE_ENV !== 'production') {
                console.log('Token refreshed' + refreshed);
              }
            } /* else {
              console.log('Token not refreshed, valid for '
                  + Math.round(this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            } */
          }).catch(() => {
            console.log('Failed to refresh token');
          });
        }, 6000);
      }).catch(() => {
        console.log("Authenticated Failed");
      });
    },
    setMap(map) {
      this.map = map;
    },
    updateUserdata() {
      axios
          .get(this.baseUrl() + "/v1/users/" + this.keycloak.subject, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.user = res.data;
          })
          .catch(()=>{
            console.log("Error fetching user data");
          });
    },
    finishRegistration(data, successCallback, failureCallback) {
      axios.post(this.baseUrl() + "/v1/registration", data, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res)=>{
            this.user = res.data.user;
            this.business = res.data.business;
            successCallback();
          })
          .catch((err)=>{
            failureCallback(err);
          });
    },
  },
})
