<template>
<v-container>
  <v-card class="pb-5">
    <v-card-title>Login</v-card-title>
    <v-card-text>
      <v-form
          ref="loginForm"
          v-model="validLoginForm"
      >
        <v-text-field
            label="Email"
            v-model="email"
            :rules="[rules.required]"
            outlined
            @keyup.enter="focusPassword()"
        ></v-text-field>
        <v-text-field
            label="Passwort"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :rules="[rules.required, rules.min]"
            outlined
            @keydown.enter="login"
            ref="passwordRef"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <span style="color: red; margin-right: 10px;">{{message}}</span>
      <v-btn @click="login" rounded color="primary" :disabled="!validLoginForm">Anmelden</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import axios from "axios";
import {mapActions} from "pinia";
import {wasticsStore} from "../store";

export default {
  name: "Login",
  data: () => ({
    show1: false,
    email: '',
    message: '',
    password: '',
    rules: {
      required: value => !!value || 'Erforderlich.',
      min: v => v.length >= 8 || 'Min 8 Zeichen',
    },
    validLoginForm: false,
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
  },
  methods: {
    focusPassword() {
      this.$refs.passwordRef.focus()
    },
    login() {
      axios
          .post(this.baseUrl + "/v1/login", {email: this.email, password: this.password})
          .then((res)=>{
            this.message = res.data.message_de;
            this.setToken(res.data.token);
            this.$router.push("/");
          })
          .catch((err) => {
            console.log(err);
            this.message = "Login fehlgeschlagen";
          });
    },
    ...mapActions(wasticsStore, ["setToken"]),
  },
  created() {
    console.log(window.location.hostname)
  }
}
</script>

<style scoped>

</style>