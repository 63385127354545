<template>
<v-container>
  <v-card>
    <v-card-title>
      EDM Daten
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          clearable
      ></v-text-field>
    </v-card-title>
    <v-expansion-panels flat>
      <v-expansion-panel
      >
        <v-expansion-panel-header>
          Filter
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-select
              v-model="blSelect"
              label="Bundesland"
              :items="blList"
              clearable
              @change="filterBL"
              outlined
              rounded
          ></v-select>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
      ></v-data-table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "axios";
import {mapState} from "pinia/dist/pinia";
import {wasticsStore} from "@/store";

export default {
  name: "EDMBasic",
  data: ()=>({
    /*

    {
      "Personen-GLN (Sammler/Behandler)": "9110016289243",
      "Personen-GLN GS1 (Sammler/Behandler)": "",
      "Nachname (Sammler/Behandler)": "",
      "Vorname (Sammler/Behandler)": "",
      "Name (Sammler/Behandler)": "\"11er\" Nahrungsmittel GmbH",
      "Kurzname (Sammler/Behandler)": "11er",
      "Alternativname (Sammler/Behandler)": "",
      "Sitz-\nBundesland": "VBG",
      "Sitz-Bezirk": "Feldkirch",
      "Sitz-Gemeinde": "Frastanz",
      "Sitz-Staat": "AT",
      "Sitz-PLZ": "6820",
      "Sitz-Ort": "Frastanz",
      "Sitz-Strasse": "Galinastraße  34"
    }

     */
    headers: [
      {text: "Personen-GLN (Sammler/Behandler)", value: "Personen-GLN (Sammler/Behandler)"},
      {text: "Name (Sammler/Behandler)", value: "Name (Sammler/Behandler)"},
      {text: "Nachname (Sammler/Behandler)", value: "Nachname (Sammler/Behandler)"},
      {text: "Vorname", value: "Vorname (Sammler/Behandler)"},
      {text: "Bundesland", value: "Sitz-Bundesland"},
      {text: "Bezirk", value: "Sitz-Bezirk"},
      {text: "Gemeinde", value: "Sitz-Gemeinde"},
      {text: "Staat", value: "Sitz-Staat"},
      {text: "PLZ", value: "Sitz-PLZ"},
      {text: "Ort", value: "Sitz-Ort"},
      {text: "Strasse", value: "Sitz-Strasse"},
    ],
    items: [],
    origItems: [],
    search: "",
    blList: [
        "W", "STM", "NOE", "OOE", "BGL", "KTN", "SBG", "TIR", "VBG",
    ],
    blSelect: null,
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["keycloak"]),
  },
  methods: {
    getData() {
      axios.get(this.baseUrl + "/v1/edm/registered", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then((res)=>{
        this.items = res.data.registered;
        this.origItems = [...this.items];
      })
      .catch(err => {console.log(err)});
    },
    filterBL() {
      this.items = [...this.origItems];
      if(this.blSelect !== null) {
        this.items = this.items.filter(el => el["Sitz-Bundesland"] === this.blSelect);
      }
    },
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped>

</style>