<template>
  <vue-dropzone ref="myVueDropzone" id="customdropzone" :options="dropzoneOptions" class="rounded-xl"
                @vdropzone-file-added="vfileAdded"
                @vdropzone-success="vsuccess"
                @vdropzone-error="verror"
                @vdropzone-removed-file="vremoved"
                @vdropzone-sending="vsending"
                @vdropzone-success-multiple="vsuccessMuliple"
                @vdropzone-sending-multiple="vsendingMuliple"
                @vdropzone-queue-complete="vqueueComplete"
                @vdropzone-total-upload-progress="vprogress"
                @vdropzone-mounted="vmounted"
                @vdropzone-drop="vddrop"
                @vdropzone-drag-start="vdstart"
                @vdropzone-drag-end="vdend"
                @vdropzone-drag-enter="vdenter"
                @vdropzone-drag-over="vdover"
                @vdropzone-drag-leave="vdleave"
                @vdropzone-duplicate-file="vdduplicate"
                :duplicateCheck="true"
                :useCustomSlot=true
                v-on:vdropzone-thumbnail="thumbnail"
  >
    <div class="dropzone-custom-title"><v-icon class="dropzone-custom-title">mdi-file-upload</v-icon>Dateien hier ablegen oder klicken um zu öffnen.</div>
  </vue-dropzone>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'Dropzone',
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function () {
    if (window.location.hostname === "localhost") {
      return {
        dropzoneOptions: {
          url: 'http://localhost:30303/v1/upload',
          thumbnailWidth: 200,
          maxFilesize: 25.0,
          //headers: { "My-Awesome-Header": "header value" },
          //addRemoveLinks: true,
          previewTemplate: this.template(),
        }
      };
    } else {
      return {
        dropzoneOptions: {
          url: '/v1/upload',
          thumbnailWidth: 200,
          maxFilesize: 25.0,
          //headers: { "My-Awesome-Header": "header value" },
          //addRemoveLinks: true,
          previewTemplate: this.template(),
        }
      }
    }
  },
  methods: {
    template() {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                    <div v-if="data-dz-uploadprogress"></div>
                    <button class="data-dz-remove"><span data-dz-remove><h3>Löschen</h3></span></button>

                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
    },
    thumbnail(file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        // eslint-disable-next-line no-unused-vars
        return setTimeout(((function(_this) {
          return function() {
            return file.previewElement.classList.add("dz-image-preview");
          };
        })(this)), 1);
      }
    },
    vfileAdded() {
      this.fileAdded = true
    },
    vfilesAdded() {
      this.filesAdded = true
    },
    vsuccess() {
      this.success = true
    },
    verror() {
      this.error = true
    },
    vremoved() {
      this.removedFile = true
    },
    vsending() {
      this.sending = true
    },
    vsuccessMuliple() {
      this.successMultiple = true
    },
    vsendingMuliple() {
      this.sendingMultiple = true
    },
    vqueueComplete() {
      this.queueComplete = true
    },
    vprogress() {
      this.progress = true
    },
    vmounted() {
      this.isMounted = true
    },
    vddrop() {
      this.dDrop = true
    },
    vdstart() {
      this.dStarted = true
    },
    vdend() {
      this.dEnded = true
    },
    vdenter() {
      this.dEntered = true
    },
    vdover() {
      this.dOver = true
    },
    vdleave() {
      this.dLeave = true
    },
    vdduplicate() {
      this.dDuplicate = true
    },
  }
}
</script>

<style>
#customdropzone {
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  border: 2px dashed #beb7bf;
}

#customdropzone .data-dz-remove {
  margin-top: 30px;
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(85, 8, 107, 0.5);
  outline-style: solid;
  width: 100px;
}

#customdropzone .data-dz-remove:hover {
  margin-top: 30px;
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(228, 37, 116, 0.5);
  outline-style: solid;
}

#customdropzone .dz-upload {
  background-color: rgba(228, 37, 116, 0.5);

}

#customdropzone .dz-preview {
  width: 180px;
  height: 180px;
  display: inline-block;
  border-radius: 10px;
}
#customdropzone .dz-preview .dz-image {
  width: 180px;
  height: 180px;
  border-radius: 10px;
}
#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  background-size: contain;
  border-radius: 10px;
}
#customdropzone .dz-preview .dz-image > img {
  width: 100%;
  border-radius: 10px;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity .2s linear;
  text-align: center;
  background-color: rgba(85, 8, 107, 0.8);
  border-radius: 10px;
}

#customdropzone > .dz-preview .dz-success-mark,
#customdropzone > .dz-preview .dz-error-mark {
  margin-left: auto;
  margin-top: auto;
  width: 100%;
  top: 35%;
  left: 0;
}
#customdropzone > .dz-preview .dz-success-mark svg,
#customdropzone .dz-preview .dz-error-mark svg {
  margin-left: auto;
  margin-right: auto;
}

.margin {
  margin-bottom: 5px;
  margin-top: 5px;
}

.position{
  margin-top: 50px;
}

</style>

//https://docs.dropzone.dev/configuration/basics/layout

