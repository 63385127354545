<template>
<v-container v-if="user.role == 'manager'">
  <v-card>
    <v-card-text>
      <v-data-table
          :items="orders"
          :headers="headers"
          :expanded.sync="expanded"
          single-expand
          show-expand
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Auftragsmanager</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="orderDialog"
                fullscreen
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  Neuer Auftrag
                </v-btn>
              </template>
              <v-card>
                <v-card-title>XXX</v-card-title>
                <v-card-text>
                  <v-form>

                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="orderDialog = false">Abbrechen</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.created="{ item }">
          {{ item.created | simpleDate }}
        </template>
        <template v-slot:item.userId="{ item }">
          {{ lookupUser(item.userId) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click="deleteOrder(item.id)">mdi-delete</v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <tr>
            <td :colspan="headers.length" class="exp-row px-2">
              <p v-if="item.customerData">{{item.customerData.firstname}} {{item.customerData.lastname}}</p>
              <p v-if="item.customerData">💻 <a :href="`mailto:${item.customerData.email}`">{{item.customerData.email}}</a></p>
              <p v-if="item.customerData">📱 <a :href="`tel:${item.customerData.phone}`">{{item.customerData.phone}}</a></p>
            </td>
          </tr>
          <tr v-for="(waste, i) in item.wasteRequests" :key="i">
            <td :colspan="headers.length" class="exp-row px-2">
              <p><v-badge :content="`${i + 1}`"><v-icon>mdi-format-list-checkbox</v-icon></v-badge> {{ waste.sn }}</p>
              <p>{{waste.description}}</p>
              <p>{{waste.pickupLocation.zip}} {{waste.pickupLocation.city}}, {{waste.pickupLocation.street}}</p>
            </td>
          </tr>

        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import {mapState} from "pinia/dist/pinia";
import {wasticsStore} from "@/store";
import axios from "axios";

export default {
  name: "OrderManager",
  data: () => ({
    expanded: [],
    headers: [
      {text: "Eingang", value: "created"},
      {text: "Benutzer", value: "userId"},
      {text: "Anfrage", value: "customerData.company"},
      {text: "Aktionen", value: "actions", sortable: false},
      {text: '', value: 'data-table-expand'},
    ],
    orderDialog: false,
    orders: [],
    usersMap: new Map(),
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["token"]),
    ...mapState(wasticsStore, ["payload"]),
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    deleteOrder(id) {
      axios
          .delete(this.baseUrl + "/v1/orders/" + id, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(() => {
            this.getOrders();
          });
    },
    getOrders() {
      axios
          .get(this.baseUrl + "/v1/orders", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.orders = res.data;
            console.log(this.orders);
            this.orders.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));
            this.orders.reverse();
          });
    },
    initOrdermanager() {
      if(this.keycloak.realmAccess.roles.includes("wastics_admin")) {
        console.log("wastics admin fetching users")
        axios
            .get(this.baseUrl + "/v1/users", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res) => {
              this.usersMap = new Map(res.data.map(i => [i.id, i]));
              console.log("Users Map");
              console.log(this.usersMap);
              this.getOrders();
            })
            .catch(()=>{
              console.log("Error fetching users");
            });
      }
    },
    lookupUser(id) {
      console.log("lookup user")
      console.log( this.usersMap instanceof Map )
      if(id === null) {
        return "unregistriert"
      }
      console.log(id)
      let user = this.usersMap.get(id);
      console.log(user)
      return user.firstname + " " + user.lastname;
    },
  },
  created() {
    this.initOrdermanager();
    //this.getOrders();
  },
  filters: {
    getUser(value) {
      if(value === null) {
        return "unregistriert"
      }
      return "Lookup User";
      /*let user = this.usersMap.get(value);
      console.log("user")
      console.log(user)
      return user.firstname + " " + user.lastname;*/
    },
    simpleDate(value) {
      if (!value) return ''
      value = value.toString()
      let date = new Date(value)
      return new Intl.DateTimeFormat('de-DE', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date)
    },
  },
}
</script>

<style scoped>

</style>