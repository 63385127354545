<template>
  <v-col>
  <v-card class="rounded-xl">
    <v-card-title>
      Rechnungen
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
    >
      <template v-slot:item.actions="{ item }">
      <v-menu
      max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs"
                 v-on="on"
                 fab
                 small
          elevation="1">
        <v-icon
            small
            class="mr-2 position"
            @click="editItem(item)"
        >
          mdi-dots-horizontal
        </v-icon>
          </v-btn>
          </template>
        <v-list>
          <v-list-item>
            Weitere Details
          </v-list-item>
          <v-list-item>
            Bearbeiten
          </v-list-item>
          <v-list-item>
            PDF Herunterladen
          </v-list-item>
          <v-list-item>
            Weiterleiten
          </v-list-item>

        </v-list>
      </v-menu>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
            :color="getColor(item.status)"
            dark
        >
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
  </v-col>
</template>

<style>

.position {
margin-left: 7px;
}

</style>

<script>
export default {
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Rechnungs-Nr',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'User', value: 'user' },
        { text: 'Ausstellungsdatum', value: 'ausstellungsdatum' },
        { text: 'Fälligkeitsdatum', value: 'fälligkeitsdatum' },
        { text: 'Betrag', value: 'betrag' },
        { text: 'Status', value: 'status' },
        {text: 'Aktionen', value: 'actions', sortable: false}
      ],
      desserts: [
        {
          name: '000078',
          user: 'Samuel',
          status: 'In Bearbeitung',
          ausstellungsdatum: '06.06.22',
          fälligkeitsdatum: '12.07.22',
          betrag: '300.00€',
        },
        {
          name: '000044',
          user: 'Markus',
          status: 'Bezahlt',
          ausstellungsdatum: '04.05.22',
          fälligkeitsdatum: '30.05.22',
          betrag: '500.00€',
        },
        {
          name: '000053',
          user: 'Stefan',
          status: 'Offen',
          ausstellungsdatum: '28.05.22',
          fälligkeitsdatum: '5.07.22',
          betrag: '1000.00€',
        },
        {
          name: '000095',
          user: 'Matthias',
          status: 'In Bearbeitung',
          ausstellungsdatum: '20.05.22',
          fälligkeitsdatum: '12.06.22',
          betrag: '300.00€',
        },
      ],
    }
  },
  methods: {
    getColor (status) {
      if (status == 'Offen') return 'red'
      else if (status == 'In Bearbeitung') return 'orange'
      else return 'green'
    },
  },
}
</script>