<template>
<v-container>
  <div style="padding: 5rem;">
    <h1>Impressum</h1>
    <p>
      <span>Wastics GmbH</span><br>
      <span>Abfallsammlung</span><br>
      <span>Beratung von Unternehmen, insbesondere in den Bereichen der Kreislaufwirtschaft und Abfalllogistik</span><br>
      <span>Handel mit Rohstoffen, Sekundärrohstoffen und Abfällen</span><br>
      <span>UID-Nr. ATU77896207</span><br>
      <span>FN 567352 t</span><br>
      <span>FB-Gericht Wien</span><br>
      <span>Sitz: 1120 Wien</span><br>
      <br>
      <span>Wastics GmbH</span><br>
      <span>Vivenotgasse 52/42</span><br>
      <span>1120 Wien</span><br><br>
      <span>hello [at] wastics.eu</span><br><br>
      <span>+43 1 9346231</span><br><br><span>Mitglied der WKÖ, Wirtschaftskammer Wien</span><br><br>
      <span>Gewerbeordnung: </span><a href="http://www.ris.bka.gv.at/">http://www.ris.bka.gv.at/</a><br>
      <span>Magistratisches Bezirksamt 12 Wien</span><br><br><br>
    </p>
    <h2>Angaben zur Online-Streitbeilegung</h2>
    <span>Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: </span>
    <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a><br>
    <span>Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</span><br><br>
    <span style="display: none;">Wastics ist eine eingetragene Marke der Wastics GmbH.</span><br>
    <span>© Wastics GmbH 2022</span><br>
    <span>Diese Webseite, das Logo und alle Texte und Bilder sind durch das Urheberrecht geschützt.</span><br>
    <span>Nutzung und Vervielfältigung sind ausdrücklich verboten.</span><br><br>
  </div>
</v-container>
</template>

<script>
export default {
  name: "Imprint"
}
</script>

<style scoped>

</style>