<template>
<v-container>
  <v-card>
    <v-card-title>Info</v-card-title>
    <v-card-text v-if="business && business.name">
      <div>{{user.firstname}} {{user.lastname}}</div>
      <div>{{user.email}}</div>
      <br>
      <h3>{{business.name}}</h3>
      <div>{{business.street}} {{business.streetNo}}, {{business.zip}} {{business.city}}, {{business.country}}</div>
      <div v-for="(type, i) in business.types" :key="i">
        {{type}}
      </div>
      {{business.types.includes('WASTE_PRODUCER')}}
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import {mapState} from "pinia";
import {wasticsStore} from "@/store";

export default {
  name: "Info",
  data: () => ({
    test: "",
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["business"]),
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
}
</script>

<style scoped>

</style>