<template>
<v-container style="max-width: 800px;">
  <h1>Allgemeine Geschäftsbedingungen</h1>
  <h2>Allgemeines und Anwendungsbereich</h2>
  <ol>
    <li>Die Wastics GmbH, Vivenotgasse 52/2/42, 1120 Wien, FN 567352 t, (im Folgenden „Betreiberin“ oder „uns/wir“ genannt), spezialisiert sich auf diverse Dienstleistungen im Abfallwirtschaftssektor. Alle Dienstleistungen, die wir im Rahmen unserer Tätigkeit anbieten, können Sie (im Folgenden auch „Nutzer“ genannt) unter der Webseite www.wastics.eu (im Folgenden „Webseite“ genannt) abrufen. Als Betreiberin dieser Webseite bieten wir unsere Dienstleistungen dort exklusiv für Unternehmen an.</li>
    <li>Die nachstehenden Allgemeinen Geschäftsbedingungen (im Folgenden „AGB“ genannt) gelten für die Benutzung unserer Webseite durch Nutzer, sowie für Verträge, welche zwischen Nutzern und uns bezüglich Dienstleistungen, welche von uns über unsere Webseite angebotenen werden, abgeschlossen werden. Unsere Dienstleistungen richten sich ausschließlich an Unternehmen. Wir schließen daher keine Verträge mit Verbrauchern im Sinne des § 1 KSchG ab.</li>
    <li>Durch die Zustimmung zu unseren AGB erklären Sie sich damit einverstanden, dass unsere AGB zur Anwendung kommen. Sollten Sie als Unternehmen AGB aufgesetzt haben, die unseren AGB entgegenstehen, stimmen Sie hiermit zu, dass diese keine Anwendung finden. Unsere AGB gehen jeglichen anderen allgemeinen oder besonderen Bestimmungen (einschließlich Einkaufsbedingungen) vor.</li>
    <li>Sonstige Vereinbarungen, Abweichungen oder Ergänzungen, ob mündlich oder schriftlich, zu unseren AGBs bedürfen vorab jeweils unserer ausdrücklichen schriftlichen Zustimmung.</li>
    <li>Die aktuellste Version unserer AGB können Sie auf unserer Webseite www.wastics.eu abrufen. Unsere AGB können dort auch jederzeit heruntergeladen und ausgedruckt werden.</li>
    <li>Sollten Sie als Nutzer Fragen haben, oder aus sonstigen Gründen Kontakt mit uns aufnehmen wollen, so können Sie das über unsere Webseite tun. Dort haben wir ein Kontaktformular eingerichtet, über das Sie uns schnell und einfach erreichen können.</li>
  </ol>
  <h2>Bereitstellung der Plattform</h2>
  <ol>
    <li>Um unsere Dienstleistungen nutzen zu können, ist eine Registrierung auf unserer Webseite notwendig (im Folgenden „Plattform“ genannt). Die Registrierung ist kostenlos. Sobald Sie sich auf unserer Plattform registriert haben, können Sie die von uns auf unserer Plattform angebotenen Dienstleistungen (im Folgenden „Features“ genannt) abrufen. Unsere Plattform bzw. unsere Features werden auf jährlicher Basis von uns ausschließlich online als Cloud-Service (als SaaS-Lösung) zur Verfügung gestellt.</li>
    <li>Durch die Freischaltung und Verwendung von Features können Kosten anfallen. Sofern Ihnen durch unsere Features Kosten entstehen, werden wir Sie darüber im Voraus informieren. Um nähere Informationen zu unseren Features zu erhalten, verweisen wir auf Punkt 3.1.</li>
    <li>Der Zugriff auf unsere Plattform ist ausschließlich für Nutzer zulässig. Durch Annahme der AGB stimmen Sie zu, dass Sie den persönlichen und vertraulichen Charakter des Plattform-Zugangs wahren und sicherstellen, dass Sie Ihre Zugangsdaten keinen Dritten, auch nicht Gehilfen, überlassen. Für jegliche Schäden, oder sonstige Nachteile, die Ihnen als Nutzer unserer Plattform aufgrund eines unrechtmäßigen Gebrauchs durch Dritte oder einer nicht ordnungsgemäßen Verwendung entstehen, haften wir nicht.</li>
    <li>Wir werden nach Kräften und soweit möglich dafür sorgen, dass ein dauerhafter und ungestörter Zugang zu unserer Plattform gewährleistet wird. Sollte der Zugang zur Plattform aufgrund von Backup- oder Wartungsarbeiten oder sonstigen außergewöhnlichen unvorhersehbaren Umständen, die außerhalb unseres Einflussbereiches liegen für den gesamten oder einen Teil eines Tages (Werktag/Arbeitsstunden) unterbrochen sein, werden wir versuchen, Sie darüber in Kenntnis zu setzen, soweit dies vernünftigerweise möglich ist.</li>
    <li>Sollten Wartungsarbeiten durchgeführt werden müssen, behalten wir uns das Recht vor, den Zugang zur gesamten oder zu einem Teil der Plattform ohne vorherige Mitteilung vorübergehend zu unterbrechen. Eine solche vorübergehende Unterbrechung ist nicht als Vertragsverletzung durch uns anzusehen. Eine diesbezügliche Haftung von uns ist ausgeschlossen.</li>
    <li>Wir haften nicht für Unterbrechungen des Zugriffs auf die Plattform, die auf Sie als Nutzer zurückzuführen sind (etwa wegen einer Nutzung, die nicht unseren Anweisungen entspricht, Änderungen in der IT-Umgebung des Nutzers, etc.) oder Unterbrechung durch höhere Gewalt, sowie außerordentliche Ereignisse, wie Blackouts, Pandemien, etc.</li>
  </ol>
  <h2>Features</h2>
  <ol>
    <li>
      Wir bieten Nutzern unserer Plattform verschiedene Features an. Bei diesen Features kann es sich um wiederkehrende oder einmalige Dienstleistungen handeln. Die Features können folgende Dienstleistungen umfassen:
      <ol>
        <li>“Matchmaking”: Dabei handelt es sich um eine technische Lösung, mit welcher Anfragen für Dienstleistungen der Abfallbewirtschaftung gestellt, sowie Angebote für die Durchführung solcher Dienstleistungen gelegt werden können.</li>
        <li>“Digitales Abfallwirtschaftskonzept” (auch “Digitales AWK”): Es handelt sich um eine technische Lösung, welche anhand eingegebener Daten ein PDF-Dokument generiert.</li>
        <li>“Abfall-Liste”: Diese technische Lösung ermöglicht dem Nutzer die Erstellung einer Liste aller im Betrieb anfallenden Abfälle.</li>
      </ol>
    </li>
  </ol>
  <h2>Matchmaking</h2>
  <ol>
    <li>Als Nutzer unserer Plattform können Sie unser Feature des Matchmakings in Anspruch nehmen. Dabei können Sie als Nutzer sowohl als Unternehmen auftreten, welches Dienstleistung von anderen Nutzern in Anspruch nehmen möchte (im Folgenden „Dienstleistungsempfänger“ genannt) oder als Unternehmen auftreten, welches Dienstleistungen für andere Nutzer anbietet (im Folgenden „Dienstleister“ genannt). Je nachdem als welche Art von Nutzer Sie auftreten, gelten für Sie die Bestimmungen des 4.2 Matchmaking – Nutzer als Dienstleistungsempfänger, oder die Bestimmungen des 4.3 Matchmaking – Nutzer als Dienstleister. Sollten Sie als Nutzer sowohl als Dienstleistungsempfänger, sowie auch als Dienstleister auftreten wollen, ist auch das möglich. In einem solchen Fall gelten die Regelungen für Dienstleistungsempfänger und Dienstleister sinngemäß.</li>
    <h3>Matchmaking - Nutzer als Dienstleistungsempfänger</h3>
    <ol>
      <li>Die Bestimmungen unter 4.2 gelten für all jene Nutzer unserer Plattform, die unser Feature des Matchmakings als Unternehmen in Anspruch nehmen wollen, welches Dienstleistungen von anderen Nutzern in Anspruch nehmen möchte.</li>
      <li>Sollte ein solcher Dienstleistungsempfänger unser Feature des Matchmakings in Anspruch nehmen wollen, so kann er dieses Feature online über unsere Plattform abrufen.</li>
      <li>Wir stellen Dienstleistungsempfängern eine Eingabemaske zur Verfügung, welche der Erstellung von unverbindlichen Anfragen für Dienstleistungen der Abfallsammlung und -behandlung dient. Der Nutzer hat die Möglichkeit, über die Plattform eine Anfrage zur Vermittlung von problemspezifischen Fachunternehmen aus dem Wirtschaftsbereich der Entsorgung und kreislaufwirtschaftlichen Beratung zu stellen.</li>
      <li>Die Anfrage ist für den Dienstleistungsempfänger kostenfrei und unverbindlich, und stellt kein Angebot im rechtlichen Sinn dar.</li>
      <li>Mit Absenden der Anfrage kommt es zwischen dem Dienstleistungsempfänger und uns zu einem für den Dienstleistungsempfänger kostenfreien Vermittlungsvertrag nach Maßgabe dieser AGB. Durch diesen Vermittlungsvertrag werden wir damit beauftragt und bevollmächtigt, je nach Art und Inhalt der Anfrage passende Dienstleister zu identifizieren, die das Anliegen des Dienstleistungsempfängers erfüllen können.</li>
      <li>Nachdem ein Dienstleistungsempfänger die Eingabemaske ausgefüllt hat, prüfen wir die Anfrage und vermitteln diese an für den Dienstleistungsempfänger geeignete Dienstleister. Die Dienstleister übersenden dem Dienstleistungsempfänger ein entsprechendes Angebot. Ein Anspruch des Dienstleistungsempfängers auf Vermittlung durch uns besteht nicht. Auch besteht kein Anspruch auf Beratung und/oder die Zusendung von Angeboten durch die Dienstleister.</li>
      <li>Wir sind zum Zweck der Vermittlung berechtigt und bevollmächtigt, mit dem Dienstleistungsempfänger in Kontakt zu treten (zum Beispiel per E-Mail oder Telefon) und die Daten und Angaben des Dienstleistungsempfängers an Dienstleister weiterzugeben, um Angebote einzuholen. Die Dienstleister dürfen den Dienstleistungsempfänger zum Zwecke der Beratung und Übersendung von Angeboten kontaktieren und hierfür die von dem Betreiber mitgeteilten Daten verarbeiten (zu näheren Informationen zu unserer Datenverarbeitung gehen Sie bitte zu unserer Datenschutzerklärung auf www.wastics.eu).</li>
      <li>Nach Erhalt der Angebote kann der Dienstleistungsempfänger frei entscheiden, ob und welches Angebot er annimmt. Nimmt er ein Angebot an, kommt der Vertrag ausschließlich zwischen dem Dienstleistungsempfänger und dem jeweiligen Dienstleister zustande. Wir werden kein Vertragspartner und treten auch nicht als Besorgungs- oder Erfüllungsgehilfe, oder Gesellschafter, Vertreter, Kommissionär oder Agent der Dienstleister auf. Innerhalb dieses Vertragsverhältnisses können gesonderte Allgemeine Geschäftsbedingungen und/oder Vertragsbedingungen gelten. Gleiches gilt, sofern der Dienstleistungsempfänger zusätzliche Leistungen des Dienstleisters in Anspruch nimmt. Auf diese Bedingungen haben wir keinerlei Einfluss. Zudem ist jegliche Haftung unsererseits, auf Gewähr- und Schadenersatz ausgeschlossen.</li>
      <li>Wir haben auf das Zustandekommen/Nichtzustandekommen eines Auftrages bzw. weitergehende Verträge keinerlei Einfluss. Ob ein Vertrag mit dem Dienstleister zustande kommt, ist abhängig von den entsprechenden Bedingungen des jeweiligen Dienstleisters und dem Vorliegen eines Angebotes und einer Annahme.</li>
      <li>Die Vermittlung durch uns ist für den Dienstleistungsempfänger kostenfrei. Für unsere Vermittlungstätigkeit erhalten wir eine Provision oder eine Pauschale pro Vermittlung (Lead-Gebühr) von den Dienstleistern.</li>
      <li>Der Betreiber fungiert nicht als Abfallsammler und verfügt in keiner Weise über den Verbleib von Abfällen, die über die Plattform vermittelt werden.</li>
    </ol>
    <h3>Matchmaking - Nutzer als Dienstleister</h3>
    <ol>
      <li>Die Bestimmungen unter 4.3 gelten für all jene Nutzer unserer Plattform, die unser Feature des Matchmakings als Unternehmen in Anspruch nehmen wollen, welches Dienstleistungen für andere Nutzer anbietet.</li>
      <li>Dienstleister erhalten über die Plattform Zugriff auf Anfragen von Dienstleitungsempfängern. Daten der Anfrage sind zunächst nur in eingeschränkter Form einsehbar und beschränken sich daher auf den politischen Bezirk des Firmenstandortes, Abfallart, Abfallmenge und Datum der Anfrage.</li>
      <li>Gegen Entrichtung einer Gebühr (im Folgenden “Lead-Gebühr” genannt), kann der Dienstleister über die Plattform weitere Daten der Anfrage freischalten und ein Angebot an den Dienstleistungsempfänger, entweder über die Plattform oder per Mail übermitteln.</li>
      <li>Die Lead-Gebühr ist in Form eines Abo Modelles ausgestaltet. Als Nutzer können Sie zwischen 4 verschiedenen Abo Modellen wählen. Haben Sie sich für ein Abo Modell entschieden, schließen Sie mit uns einen Abo Vertrag ab. Nach Abschluss dieses Abo Vertrages können Sie – ohne, dass Ihnen abgesehen von der Lead-Gebühr noch weitere Kosten entstehen – alle vorhandenen Daten der Dienstleistungsempfänger freischalten. Je nachdem welches Abo Modell Sie gewählt haben, haben Sie ein gewisses Kontingent an Freischaltungen, die Sie im Jahr nutzen können, um weitere Informationen der Dienstleistungsempfänger einsehen zu können.</li>
      <li>
        Sie können zwischen folgenden Abo Modellen wählen:
        <ol>
          <li>Abo Modell 1: Sie bekommen 5 Freischaltungen pro Jahr, für einen monatlichen Preis von EUR 120,--</li>
          <li>Abo Modell 2: Sie bekommen 10 Freischaltungen pro Jahr, für einen monatlichen Preis von EUR 190,--</li>
          <li>Abo Modell 3: Sie bekommen 20 Freischaltungen pro Jahr, für einen monatlichen Preis von EUR 360,--</li>
          <li>Abo Modell 4: Sie bekommen eine unlimitierte Anzahl an Freischaltungen pro Jahr, Preis auf Anfrage</li>
        </ol>
      </li>
      <li>Eine Rechnung wird an die vom Dienstleister bei der Registrierung bekannt gegebene Rechnungsanschrift, oder an die bekannt gegebene E-Mail-Rechnungsadresse versandt.</li>
      <li>Die Abo Modelle können nach Ablauf eines Jahres unter Einhaltung einer einmonatigen Kündigungsfrist schriftlich gekündigt werden. Die schriftliche Kündigung muss an unsere Postadresse [Vivenotgasse 52/42, 1220 Wien], oder an uns per E-Mail an [office@wastics.eu] zeitgerecht zugesendet werden. Die Kündigung gilt als zeitgerecht, wenn sie per Post am letzten Tag der Kündigungsfrist abgesendet wurde, oder per E-Mail am letzten Tag der Kündigungsfrist an uns zugesendet wurde. Sollten Sie Ihr Abo nicht rechtzeitig kündigen, verlängert sich Ihr Abo um ein weiteres Jahr.</li>
      <li>Ein zwischen dem Dienstleistungsempfänger und dem Dienstleister abgeschlossener Vertrag kommt nur zwischen diesen beiden Parteien zustande. Wir sind keine Vertragspartei. Auch haften wir nicht für allfällige Ansprüche, die eine der beiden Vertragsparteien gegen die andere geltend macht. Mit erfolgreicher Vermittlungstätigkeit ist unsere vertraglich vereinbarte Leistung abgeschlossen.</li>
    </ol>
  </ol>
  <h2>Digitales Abfallwirtschaftskonzept</h2>
  <ol>
    <li>Als Nutzer unserer Plattform können Sie unser Feature des Digitalen Abfallwirtschaftskonzeptes in Anspruch nehmen. Dabei können Sie als Nutzer sowohl als Unternehmen auftreten, welches ein Abfallwirtschaftskonzept erstellt haben möchte (im Folgenden „Abfallerzeuger“ genannt) oder als Unternehmen auftreten, welches die Plattform für die Erstellung von Abfallwirtschaftskonzepten für andere Unternehmen nutztanbietet (im Folgenden „Abfallberater“ genannt). Je nachdem als welche Art von Nutzer Sie auftreten, gelten für Sie die Bestimmungen des 5.3 Digitales Abfallwirtschaftskonzept – Nutzer als Abfallerzeuger, oder die Bestimmungen des 5.4 Digitales Abfallwirtschaftskonzept – Nutzer als Abfallberater.</li>
    <li>Der Betreiber übernimmt keine Garantie und Verantwortung für die Vollständigkeit, inhaltliche Richtigkeit und den Umfang der in diesem Dokument angeführten Daten, da der Nutzer frei in der Eingabe dieser Daten ist und nur jene Daten verwendet werden können, die vom Nutzer bereitgestellt werden.</li>
    <li>
      <h3>Digitales Abfallwirtschaftskonzept – Nutzer als Abfallerzeuger</h3>
      <ol>
        <li>Die Bestimmungen unter 5.3 gelten für all jene Nutzer unserer Plattform, die unser Feature des Digitalen Abfallwirtschaftskonzeptes als Unternehmen in Anspruch nehmen wollen, welches ein Abfallwirtschaftskonzept für Ihr Unternehmen erstellt bekommen möchte.</li>
        <li>Sollte ein solcher Abfallerzeuger unser Feature des Digitalen Abfallwirtschaftskonzeptes in Anspruch nehmen wollen, so kann er dieses Feature online über unsere Plattform abrufen.</li>
        <li>Um unser Feature des Digitalen Abfallwirtschaftskonzeptes als Abfallerzeuger nutzen zu können, muss der Abfallerzeuger an uns eine jährliche Lizenzgebühr bezahlen. Die jährliche Lizenzgebühr beträgt pro Jahr EUR 499,--. Wir behalten uns das Recht vor, unser Feature des Digitalen Abfallwirtschaftskonzeptes noch weiter auszubauen. Sollten die unter diesem Feature gebotenen Leistungen von uns wesentlich erweitert werden, behalten wir uns das Recht vor preisliche Anpassung vorzunehmen. Selbstverständlich werden wir Sie über solche Änderungen rechtzeitig in Kenntnis setzen, und unseren bestehenden Vertrag mit Ihnen nur durch vorherige schriftliche Zustimmung von Ihnen ändern.</li>
        <li>Anhand der von Ihnen als Abfallerzeuger eingegeben Daten generiert die Betreiberin der Abfallberater ein PDF-Dokument, welches vom Abfallerzeuger heruntergeladen werden kann. Dieses Dokument entspricht grds. dem vom Gesetzgeber geforderten Umfang und Inhalt für ein Abfallwirtschaftskonzept gemäß § 10 AWG 2002. Eine Haftung für Vollständigkeit, inhaltliche Richtigkeit und Umfang dieses Digitalen Abfallwirtschaftskonzeptes wird jedoch von uns nicht übernommen.</li>
      </ol>
    </li>
    <li>
      <h3>Digitales Abfallwirtschaftskonzept – Nutzer als Abfallberater</h3>
      <ol>
        <li>Die Bestimmungen unter 5.4 gelten für all jene Nutzer unserer Plattform, die unser Feature des Digitalen Abfallwirtschaftskonzeptes als Unternehmen in Anspruch nehmen wollen, welches ein Abfallwirtschaftskonzept für andere Unternehmen erstellen möchte.</li>
        <li>
          Um die Plattform für die Erstellung von Abfallwirtschaftskonzepten für verschiedene Rechtspersonen nutzen zu können, hat der Abfallberater ein jährliches Entgelt zu entrichten. Die höhe der jährlichen gebühr richtet sich nach der Anzahl an Klienten/Unternehmen für welche der Berater ein AWK erstellen möchte.
          <ol>
            <li>Bei einer Anzahl von 1-5 Klienten im Jahr verrechnen wir dem Abfallberater EUR 499,--</li>
            <li>Bei einer Anzahl von 6-10 Klienten im Jahr verrechnen wir dem Abfallberater EUR 699,--</li>
            <li>Bei einer Anzahl von über 10 Klienten werden wir Ihnen als Nutzer den Preis auf Anfrage bekanntgeben.</li>
          </ol>
        </li>
      </ol>
    </li>
  </ol>
  <h2>Abfall-Liste</h2>
  <ol>
    <li>Wir stellen Nutzern eine Eingabemaske zur Verfügung, mit der eine Liste generiert werden kann, die die Abfallarten und Abfallmengen des Nutzers abbildet. </li>
    <li>Die Nutzung dieses Features ist für Nutzer kostenlos.</li>
    <li>Die Liste wird als PDF-Dokument generiert und steht dem Nutzer zum Download bereit.</li>
    <li>Der Betreiber übernimmt keine Garantie, Verantwortung und Haftung für die Vollständigkeit, inhaltliche Richtigkeit und Umfang der in diesem Dokument angeführten Daten, da der Nutzer frei in der Eingabe dieser Daten ist und nur jene Daten verwendet werden können, die vom Nutzer bereitgestellt werden.</li>
  </ol>
  <h2>Nutzerpflichten</h2>
  <ol>
    <li>Dem Nutzer ist es untersagt bei der Inanspruchnahme unserer Dienste, die Rechte Dritter zu verletzen oder Dritte sonst zu benachteiligen. Der Missbrauch oder die Vornahme rechtswidriger Handlungen ist untersagt. Der Nutzer ist verpflichtet, die Nutzung unserer Dienste mit der größtmöglichen Sorgfalt durchzuführen. Insbesondere hat der Nutzer jegliche Handlungen zu unterlassen, die geeignet sind, die Serverintegrität oder -stabilität der Plattform unmittelbar oder mittelbar zu beeinträchtigen oder in Rechte Dritter einzugreifen.</li>
    <li>Die Eingabe und Übermittlung der persönlichen Daten durch den Nutzer erfolgt online über die von uns vorbereiteten Eingabemasken oder über andere ausdrücklich von uns im Einzelfall vorgesehene Übermittlungswege. Für die Vollständigkeit und Richtigkeit sämtlicher vom Nutzer in die Eingabemaske eingegebenen oder sonst übermittelten Daten ist ausschließlich der Nutzer selbst verantwortlich. Für den Fall der Übermittlung von falschen oder unrichtigen persönlichen Daten durch den Nutzer behalten wir uns vor, diese Nutzer von der Inanspruchnahme der angebotenen bzw. zur Verfügung gestellten Dienstleistungen auszuschließen und Ersatz für dadurch eingetretene Schäden, inklusive entgangenem Gewinn, indirekten Schäden und Mangelfolgeschäden, zu verlangen.</li>
    <li>Der Nutzer agiert selbstständig. Er ist selbst für die Abfuhr von Steuern, insbesondere der Est und USt verantwortlich. Der Nutzer ist selbst dafür verantwortlich gewerberechtliche, abfallwirtschaftliche, oder sonstige Vorschriften inklusive aller öffentlich-rechtlichen Vorschriften zu erfüllen.</li>
  </ol>
  <h2>Gewährleistung und Haftung</h2>
  <ol>
    <li>Die auf unserer Seite angebotenen Informationen stellen keine Beratungsleistung dar. Die Informationen sollen den Nutzern lediglich zur Entscheidungsfindung dienen. Auf die Entscheidungsfindung des Nutzers haben wir keinerlei Einfluss. Die von uns bereitgestellten Informationen stellen keine Zusage einer Gewährleistung, Garantie, oder einer sonstigen Zusicherung dar. Die von uns angebotenen Dienstleistungen stellen insbesondere auch keine steuer- oder rechtliche Beratungsleistung dar. Nutzer unserer Webseite müssen daher die mit Dienstleistern abgeschlossenen Verträge gesondert prüfen.</li>
    <li>Für sämtliche Dienstleistungen von uns, die über unsere Plattform angeboten und erbracht werden, leisten wir lediglich Gewähr dafür, dass diese die gewöhnlich vorausgesetzten und ausdrücklich vereinbarten Eigenschaften erfüllen. Weitere Gewährleistungen und/oder Haftungen werden nicht übernommen. Unsere Haftung ist soweit gesetzlich zulässig ausgeschlossen. Eine Haftung für Mangelfolgeschäden, indirekte Schäden und entgangenen Gewinn ist ausgeschlossen.</li>
    <li>Für von uns zur Verfügung gestellte Leistungen Dritter, insbesondere von Leistungen Dritter im Rahmen von Dienstleistungen, die auf unserer Plattform angeboten werden, wird keinerlei Gewähr oder Haftung übernommen.</li>
    <li>Die Gewährleistungsfrist für die Geltendmachung von Ansprüchen aus der Verletzung der Gewährleistung läuft bis zum Ablauf von 12 Monaten ab dem Tag der jeweiligen Leistungserbringung. Dass ein allfälliger Leistungsausfall auf eine mangelhafte Leistungserbringung oder sonstige Ausführungshandlung von uns zurückzuführen ist, hat der Nutzer zu beweisen. Während der Gewährleistungsfrist hervorkommende Gewährleistungsansprüche sind bei sonstigem Verlust sämtlicher Gewährleistungsbehelfe umgehend schriftlich anzuzeigen.</li>
    <li>Wir übernehmen keine wie auch immer geartete Haftung und/oder Gewährleistung für entfallene, schlechterfüllte oder aus sonstigem Grund mangelhafte Leistungen gemäß diesen AGB, die durch oder in Zusammenhang mit einem Ereignis verursacht wurden, welches durch uns nicht beeinflusst werden kann (im Folgenden „höhere Gewalt“ genannt), wie insbesondere aufgrund eines unvorhergesehenen Naturereignisses, einer Seuche oder Pandemie, Blackout, Auswirkung eines Krieges, oder sonstigen behördlichen Maßnahmen mit ähnlicher Wirkung. Als höhere Gewalt gelten zudem sämtliche Ereignisse, die – selbst, wenn sie vorhersehbar waren – außerhalb unseres Einflussbereiches liegen oder deren Auswirkung auf die Vertragserfüllung durch zumutbare Bemühungen von uns nicht verhindert werden können. Im Falle eines Ereignisses höhere Gewalt verliert der Nutzer jegliche Ansprüche auf allfällige Vorteile im Zusammenhang mit unseren Dienstleistungen. Wir können in einem solchen Fall auch nicht zu einer allfälligen Ersatzleistung verpflichtet werden. Etwaige gesetzliche Ansprüche der Nutzer bleiben unberührt.</li>
  </ol>
  <h2>Bewertungen/Erlaubnis der Veröffentlichung von Nutzerbewertungen</h2>
  <ol>
    <li>Nutzer können unseren Service über unsere Plattform bewerten. Mit Abgabe einer Bewertung erteilen Nutzer uns die Erlaubnis, die abgegebene Bewertungen inklusive seines Namens im Internet auf dem Portal und auf Drittseiten, wie z.B. Trustpilot, zu veröffentlichen. Hierzu räumt der Nutzer dem Betreiber ein einfaches, unwiderrufliches, räumlich und inhaltlich unbeschränktes Nutzungsrecht an den Bewertungen zur Veröffentlichung dieser im Internet ein.</li>
  </ol>
  <h2>Datenschutz</h2>
  <ol>
    <li>Hinsichtlich des Datenschutzes gelten die Datenschutzinformationen in unserer Datenschutzerklärung. Diese kann der Nutzer auf unserer Webseite www.wastics.eu abrufen, downloaden und ausdrucken.</li>
  </ol>
  <h2>Sonstiges</h2>
  <ol>
    <li>Wir sind zur Änderung dieser AGB und sämtlicher damit im Zusammenhang stehender Dokumente zu jeder Zeit berechtigt. Wir werden Änderungen unter Setzung einer angemessenen Frist vor Inkrafttreten per Newsletter (an die vom Nutzer angegebene Adresse) oder per Newseintrag ankündigen. Nutzer haben das Recht diesen Änderungen zu widersprechen. Widerspricht ein Nutzer unseren geänderten Bedingungen nicht innerhalb eines Monats nach Bekanntgabe, so werden diese Vertragsbestandteil. Im Falle eines Widerspruchs sind wir ermächtigt den Nutzer von der Verwendung unserer Plattform, sowie den darauf angebotenen Dienstleistungen, jederzeit auszuschließen.</li>
    <li>Änderungen oder Ergänzungen zu diesen AGB bedürfen der Schriftform.</li>
    <li>Sollten wir den Nutzern Mitteilungen zusenden wollen, dann werden wir dies über die uns bekannt gegebenen E-Mail Adressen tun. Mitteilungen, die an die angegebene E-Mail Adresse gesendet werden, gelten nach einem Werktag als zugestellt. Nutzer unserer Plattform sind daher verpflichtet immer ihre aktuelle E-Mail Adresse bekanntzugeben.</li>
    <li>Sofern eine Bestimmung dieser AGB unwirksam oder unvollständig ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.</li>
    <li>Der Vertrag unterliegt österreichischem Recht unter Ausschluss der Verweisungsnormen und des Übereinkommens der Vereinten Nationen über den internationalen Warenkauf (UNCITRAL).</li>
    <li>Ausdrücklich zuständig für sämtliche Streitigkeiten, die sich aus den AGB oder in Zusammenhang mit diesen, inklusive deren Interpretation, oder aus den zugrundeliegenden vertraglichen Verhältnissen ergeben, ist das für Handelssachen zuständige Gericht in Wien, Innere Stadt.</li>
  </ol>
  <!--
  <ol>
    <li>
      Die nachstehenden Allgemeinen Geschäftsbedingungen (im Folgenden auch: „AGB“) gelten ausschließlich für das durch die Wastics GmbH, Vivenotgasse 52/2/42, 1120 Wien (im Folgenden auch: „Betreiber“ oder „wir/uns“) betriebene Portal wastics.eu (im Folgenden auch: „Portal“) und dem Interessenten (Nutzer) abgeschlossenen Verträge wie auch für sämtliche der von uns zur Verfügung gestellten Leistungen, speziell die Beratung. Von diesen Allgemeinen Geschäftsbedingungen abweichende Bedingungen des Nutzers gelten nicht.
    </li>
    <li>
      Die Angebote auf dem Portal richten sich an juristische Personen und an Verbraucher, die das 18. Lebensjahr vollendet haben und unbeschränkt geschäftsfähig sind. Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, das überwiegend weder ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden kann.
    </li>
    <li>
      Die Vertragssprache ist Deutsch. Die Geschäftsbeziehungen zwischen uns und dem Nutzer unterliegen dem Recht der Bundesrepublik Österreich unter Ausschluss des UN-Kaufrechts. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.
    </li>
    <li>
      Verbraucher haben die Möglichkeit eine alternative Streitbeilegung zu nutzen. Der folgende Link der EU-Kommission (auch OS-Plattform genannt) enthält Informationen über die Online-Streitschlichtung und dient als zentrale Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten, die aus Online-Kaufverträgen oder Online-Dienstleistungsverträgen erwachsen: https://ec.europa.eu/consumers/odr. Zu einer Teilnahme an einem Schlichtungsverfahren sind wir weder bereit noch verpflichtet.
    </li>
    <li>
      Informationspflicht gem. Verbraucherstreitbeilegungsgesetz: Wir sind nicht zur Teilnahme an weiteren Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle bereit und/oder verpflichtet.
    </li>
    <li>
      Diese AGB können in der jeweils aktuellen Version jederzeit auf dem Portal abgerufen werden.
    </li>
  </ol> -->
  <!--
  <h2>Leistungen des Betreibers</h2>
  <ol>
    <li>
      Der Betreiber stellt auf dem Portal allgemeine Informationen zum Thema kostenlos zur Verfügung, die sich nicht konkret auf den Verkauf von Dienstleistungen beziehen. Die Informationen dienen lediglich der Entscheidungsfindung über das „Ob“ und das „Wie“ des Vorhabens.
    </li>
    <li>
      Der Betreiber stellt den Nutzern, die sich registrieren, von Zeit zu Zeit Informationen zum Thema und sonstige dazu nahe Themen zur Verfügung.
    </li>
    <li>
      Der Betreiber vermittelt schließlich für die registrierten und nicht registrierten Nutzer kostenlos und unverbindlich zur Anfrage passende Fachunternehmen, welche zunächst beraten, ein Angebot erstellen und die Dienstleistung durchführen können.
    </li>
  </ol>
  <h2>Vertragsschluss</h2>
  <ol>
    <li>Der Nutzer hat die Möglichkeit, über das Portal eine Anfrage zur Vermittlung von problemspezifischen Fachfirmen aus dem Wirtschaftsbereich der Entsorgung und kreislaufwirtschaftlichen Beratung zu stellen. Die Anfrage ist für den Nutzer kostenfrei und unverbindlich und stellt noch kein Angebot im Rechtssinne zur Beauftragung einer Dienstleistung dar.</li>
    <li>Mit Absenden der Anfrage bestätigt der Nutzer diese AGB und es kommt zwischen dem Nutzer und dem Betreiber ein für den Nutzer kostenfreier Vermittlungsvertrag nach Maßgabe dieser AGB zu Stande. Mit diesem Vermittlungsvertrag wird der Betreiber beauftragt, je nach Art und Inhalt der Anfrage passende Unternehmen/Dienstleistern zur Seite zu stellen, welche das Problem lösen können.</li>
    <li>Der Betreiber prüft die Anfrage und vermittelt bis zu 3 geeignete Unternehmen/Dienstleister. Die Unternehmen/Dienstleister beraten und übersenden dem Nutzer jeweils ein entsprechendes Angebot. Ein Anspruch des Nutzers auf Vermittlung durch den Betreiber besteht nicht. Auch besteht kein Anspruch auf Beratung und/oder die Zusendung von Angeboten durch die vermittelten Unternehmen/Dienstleister.</li>
    <li>Der Betreiber ist zum Zweck der Vermittlung berechtigt, mit dem Nutzer in Kontakt zu treten (zB. per E-Mail oder telefonisch) und die Daten und Angaben des Nutzers an Unternehmen/Dienstleister weiterzugeben, um Angebote einzuholen. Die Unternehmen/Dienstleister dürfen den Nutzer zum Zwecke der Beratung und Übersendung von Angeboten Kontakt mit dem Nutzer aufnehmen und hierfür die von dem Betreiber mitgeteilten Daten verarbeiten.</li>
    <li>Nach Erhalt der Angebote kann der Nutzer frei entscheiden, ob und wenn ja welches Angebot er annimmt. Nimmt er ein Angebot an, kommt der Vertrag ausschließlich zwischen dem Nutzer und dem jeweiligen Unternehmen/Dienstleister zustande. Innerhalb dieses Vertragsverhältnisses können gesonderte Allgemeine Geschäftsbedingungen und/oder Vertragsbedingungen gelten. Gleiches gilt sofern der Nutzer zusätzliche Leistungen des Unternehmens/Dienstleisters in Anspruch nimmt. Der Betreiber wird nicht Vertragspartner, sondern tritt ausschließlich als Vermittler auf. Der Betreiber ist auch kein Vertreter oder Erfüllungsgehilfe der Unternehmen/Dienstleister.</li>
    <li>Der Betreiber hat auf das Zustandekommen/Nichtzustandekommen eines Auftrages bzw. weitergehende Verträge keinerlei Einfluss. Ob ein Vertrag mit dem Unternehmen/Dienstleister zustande kommt, ist abhängig von den entsprechenden Bedingungen des jeweiligen Unternehmens/Dienstleisters.</li>
    <li>Die Vermittlung durch den Betreiber ist für den Nutzer kostenfrei. Der Betreiber erhält für die Vermittlung eine Provision oder eine Pauschale pro Vermittlung von den Unternehmen/Dienstleistern.</li>
  </ol>
  <h2>Gewährleistung</h2>
  <ol>
    <li>
      Jegliche Informationen über das Thema durch den Betreiber stellen weder eine Beratungsleistung noch eine Verkaufstätigkeit für den Nutzer dar, sondern dienen der Erleichterung einer selbständigen Entscheidung des Nutzers, auf die der Betreiber keinen Einfluss hat. Informationen sind weder als Zusage einer Garantie noch als Zusicherung zu verstehen. Die Leistungen des Betreibers umfassen insbesondere auch keine Rechts- oder Steuerberatung. Der Nutzer ist dazu verpflichtet, den genauen Inhalt des Vertrages mit dem Unternehmen/ Dienstleister zu prüfen.
    </li>
    <li>Der Betreiber übernimmt keine Gewährleistung und/oder Haftung für durch den Betreiber vermittelte Unternehmen/Dienstleister und durch dessen ausgeführte Arbeiten. Für etwaige in dem Vertragsverhältnis zwischen Nutzer und Unternehmen/Dienstleister auftretende Pflichtverletzungen haftet ausschließlich das jeweilige Unternehmenen bzw. der jeweilige Dienstleister.</li>
  </ol>
  <h2>Nutzerpflichten</h2>
  <ol>
    <li>Dem Nutzer ist es untersagt bei der Inanspruchnahme unserer Dienste, die Rechte Dritter zu verletzen oder Dritte sonst zu belästigen. Der Missbrauch oder die Vornahme rechtswidriger Handlungen ist untersagt. Der Nutzer ist verpflichtet, die Nutzung unserer Dienste mit der größtmöglichen Sorgfalt durchzuführen. Insbesondere hat der Nutzer jegliche Handlungen zu unterlassen, die geeignet sind, die Serverintegrität oder -stabilität des Portals unmittelbar oder mittelbar zu beeinträchtigen.</li>
    <li>Die Eingabe und Übermittlung der persönlichen Daten durch den Nutzer erfolgt online über die von dem Betreiber vorbereiteten Eingabemasken oder über andere ausdrücklich von dem Betreiber im Einzelfall vorgesehene Übermittlungswege. Für die Vollständigkeit und Richtigkeit sämtlicher vom Nutzer in die Eingabemaske eingegebenen oder sonst übermittelten Daten ist ausschließlich der Nutzer selbst verantwortlich. Für den Fall der Übermittlung von vorsätzlich falschen oder unwahren persönlichen Daten durch den Nutzer behält sich der Betreiber vor, ihn von der Inanspruchnahme der angebotenen bzw. zur Verfügung gestellten Leistungen auszuschließen und Ersatz für dadurch eingetretene Schäden zu verlangen.</li>
  </ol>
  <h2>Bewertungen/Erlaubnis der Veröffentlichung von Nutzerbewertungen</h2>
  <ol>
    <li>Leads können den Service des Betreibers bewerten. Mit Abgabe einer Bewertung erteilen Leads dem Betreiber die Erlaubnis, die von ihm abgegebenen Bewertungen inklusive seines Namens im Internet auf dem Portal und auf Drittseiten wie z.B. Trustpilot zu veröffentlichen. Hierzu räumt der Nutzer dem Betreiber ein einfaches, unwiderrufliches, zeitlich, räumlich und inhaltlich unbeschränktes Nutzungsrecht an den Bewertungen zur Veröffentlichung dieser im Internet ein.</li>
  </ol>
  <h2>Haftung</h2>
  <ol>
    <li>Wir haften unbeschränkt, soweit die Schadensursache auf Vorsatz oder grober Fahrlässigkeit im Zusammenhang mit dem Betrieb der Plattform beruht. Wir haften auch für die leicht fahrlässige Verletzung von wesentlichen Pflichten (Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet) sowie für die Verletzung von Kardinalpflichten (Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut), jedoch nur für den vorhersehbaren, vertragstypischen Schaden. Für die leicht fahrlässige Verletzung anderer als der vorstehenden Pflichten haften wir nicht.</li>
    <li>Die Haftungsbeschränkungen des vorstehenden Absatzes gelten nicht bei der Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie, bei arglistig verschwiegenen Mängeln, bei einer Haftung nach dem Produkthaftungsgesetz und bei datenschutzrechtlichen Anspruchsgrundlagen.</li>
    <li>Ist unsere Haftung ausgeschlossen oder beschränkt, so gilt dies ebenfalls für die persönliche Haftung von Organen, Angestellten, Vertretern und Erfüllungsgehilfen.</li>
  </ol>
  <h2>Datenschutz</h2>
  <p>
    Hinsichtlich des Datenschutzes gelten die Datenschutzinformationen in der Datenschutzerklärung des Betreibers.
  </p>
  <h2>Salvatorische Klausel</h2>
  <p>
    Sofern eine Bestimmung dieser AGB unwirksam ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.
  </p> -->
</v-container>
</template>

<script>
export default {
  name: "AGB"
}
</script>

<style scoped>
h2 {
  color: rebeccapurple;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
}
li {
  margin-bottom: 1rem;
  text-align: justify;
}
</style>