<template>
<v-container>
  <v-card>
    <v-card-title>Test Overview</v-card-title>
    <v-card-text>
      <v-textarea v-model="result"></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="getUser">User</v-btn>
      <v-btn @click="getLocation">Locations</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import {mapState} from "pinia/dist/pinia";
import {wasticsStore} from "@/store";
import axios from "axios";

export default {
  name: "TestView",
  data: () => ({
    result: null,
    message: "",
    snackbar: false,
    locations: null,
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["token"]),
    ...mapState(wasticsStore, ["payload"]),
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    getUser() {
      axios
          .get(this.baseUrl + "/v1/users/" + this.keycloak.subject, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            console.log("User")
            console.log(res.data)
            this.result = JSON.stringify(res.data);
          })
          .catch(()=>{
            console.log("Error fetching user data");
          });
    },
    getLocation() {
      axios.get(this.baseUrl + "/v1/locations", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res)=>{
            console.log("Location");
            console.log(res.data);
            this.result = res.data;
            this.locations = res.data.filter(el => el.deleted === null);
          })
          .catch(() => {
            this.message = "Es ist ein Fehler aufgetreten";
            this.snackbar = true;
          })
    },
  },
}
</script>

<style scoped>

</style>