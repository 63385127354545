<template>
<v-container>
  <v-slide-y-transition>
    <v-card v-show="!initializeBaseData">
      <v-card-title>Wastics wird geladen</v-card-title>
      <v-card-text class="text-center">
        <v-progress-circular indeterminate color="primary" size="170" width="15" class="mt-5"></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="show0">
      <v-card-title>Willkommen bei Wastics</v-card-title>
      <v-card-text>
        <p>Bitte ergänze folgende Informationen, damit wir unseren Service passend auf die individualisieren können.</p>
        <v-form v-on:submit.prevent="true">
          <v-switch v-model="isWasteCreator" inset label="Ich möchte Abfälle entsorgen"></v-switch>
          <v-switch v-model="isWasteDisposer"  inset label="Ich bin Entsorgungsdienstleister (Behandler)"></v-switch>
          <v-switch v-model="isWasteCollector"  inset label="Ich bin Entsorgungsdienstleister (Sammler)"></v-switch>
          <v-switch v-model="isWasteConsultant"  inset label="Ich bin Abfall- und Umweltberater:in"></v-switch>
          <v-switch v-model="isConverter"  inset label="Ich suche nach Sekundärrohstoffen für meinen Betrieb (Converter)"></v-switch>
          <v-switch v-model="isSupplier"  inset label="Ich biete Sekundärrohstoffe an (Supplier)"></v-switch>
          <v-switch v-model="isTransport"  inset label="Ich bin Transportdienstleister"></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" @click="showDetails" :disabled="!validateSelection">Weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="data0">
      <v-card-title>Meine Daten</v-card-title>
      <v-card-text>
        <v-form
            v-on:submit.prevent="true"
            ref="dataForm0"
            v-model="validDataForm0"
        >
          <div v-if="isWasteDisposer || isWasteCollector">
            <p>Wie lautet die Personen-GLN (Sammler/Behandler)? (* Pflichtfeld)</p>
            <v-text-field label="GLN *" v-model="data.gln" :rules="[rules.required, rules.gln]" v-on:keyup.enter="showData1" filled rounded></v-text-field>
          </div>
          <div v-else>
            <p>Wie lautet die Personen-GLN, falls bekannt?</p>
            <v-text-field label="GLN" v-model="data.gln" :rules="[rules.gln]" v-on:keyup.enter="showData1" filled rounded></v-text-field>
          </div>
          <p>Wie lautet die UID, falls bekannt?</p>
          <v-text-field label="UID" v-model="data.uid" v-on:keyup.enter="showData1" filled rounded></v-text-field>
          <p>Wie lautet die Firmenbuchnummer, falls bekannt?</p>
          <v-text-field label="FN" v-model="data.fn" v-on:keyup.enter="showData1" filled rounded></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn rounded @click="showChoice">Zurück</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="showData1" text v-if="!(isWasteCollector || isWasteDisposer)">Überspringen</v-btn>
        <v-btn @click="showData1" :loading="glnLoading" color="primary" rounded :disabled="!validDataForm0">Weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="data1">
      <v-card-title>Meine Daten</v-card-title>
      <v-card-text>
        <v-form
            v-on:submit.prevent="true"
            ref="dataForm1"
            v-model="validDataForm1"
        >
          <p>Wie heißt die Firma? (Pflichtfeld)</p>
          <v-text-field label="Firma *" v-model="data.name" :rules="[rules.required]" v-on:keydown.enter="showData2" filled rounded></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showData2" color="primary" rounded :disabled="!validDataForm1">Weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="data2">
      <v-card-title>{{data.name}}</v-card-title>
      <v-card-text>
        <v-form v-on:submit.prevent="true">
          <p>In welchem Land befindet sich der Firmensitz?</p>
          <v-select label="Land *" v-model="data.country" :rules="[rules.required]" :items="countryList" @change="onCountryChange" filled rounded></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="showData1" rounded>Zurück</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="showData3" color="primary" rounded>Weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="data3">
      <v-card-title>{{data.name}} ({{ data.country }})</v-card-title>
      <v-card-text>
        <v-form
            v-on:submit.prevent="true"
            ref="dataForm3"
            v-model="validDataForm3"
        >
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field label="Straße *" v-model="data.street" :rules="[rules.required]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Hausnummer *" v-model="data.streetNo" :rules="[rules.required]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Adresszusatz" v-model="data.additional" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="PLZ *" v-model="data.zip" :rules="[rules.required]" v-on:blur="checkPLZ" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field label="Ort *" v-model="data.city" :rules="[rules.required]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Telefon" v-model="data.phone" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Website" v-model="data.url" filled rounded></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="showData2" rounded>Zurück</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="showData4" color="primary" rounded :disabled="!validDataForm3">Weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="data4">
      <v-card-title>Vielen Dank</v-card-title>
      <v-card-text>
        <p>
          Deine Registrierung ist jetzt abgeschlossen.
        </p>
        <p>
          Unser Funktionsumfang wird laufend ausgebaut. In deinem Dashboard findest du eine Übersicht der wichtigsten Funktionen.<br>
          Wir werden deinen Account in Kürze auch verifizieren. Dadurch werden dir in Zukunft weitere Funktionen zur Verfügung stehen.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn to="/" color="primary" rounded outlined>Dashboard</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>
  <v-slide-y-transition>
    <v-card v-show="false">
      <v-card-title>Meine Daten</v-card-title>
      <v-card-text>
        <v-form v-on:submit.prevent="true">
          <v-row>
            <v-col cols="12">
              <v-select label="Land *" v-model="data.country" :rules="[rules.required]" :items="countryList" @change="onCountryChange" filled rounded></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Firma *" v-model="data.name" :rules="[rules.required]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="GLN" v-model="data.gln" :rules="[rules.gln]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="UID" v-model="data.uid" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Vorname" filled rounded readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Nachname" filled rounded readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field label="Straße *" v-model="data.street" :rules="[rules.required]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Hausnummer *" v-model="data.streetNo" :rules="[rules.required]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Adresszusatz" v-model="data.additional" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="PLZ *" v-model="data.zip" :rules="[rules.required]" v-on:blur="checkPLZ" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field label="Ort *" v-model="data.city" :rules="[rules.required]" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Telefon" v-model="data.phone" filled rounded></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Website" v-model="data.url" filled rounded></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn rounded @click="showChoice">Zurück</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" rounded @click="showChoice">Weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-transition>

</v-container>
</template>

<script>
import axios from "axios";
import {mapState} from "pinia";
import {wasticsStore} from "@/store";
import {mapActions} from "pinia";

export default {
  name: "Intro",
  data: () => ({
    show0: false,
    show1: false,

    data0: false,
    data1: false,
    data2: false,
    data3: false,
    data4: false,

    validDataForm0: false,
    validDataForm1: false,
    validDataForm2: false,
    validDataForm3: false,

    from: false,
    to: false,

    glnLoading: false,

    isWasteCreator: false,
    isWasteDisposer: false,
    isWasteCollector: false,
    isWasteConsultant: false,
    isConverter: false,
    isSupplier: false,
    isTransport: false,

    countryList: [
        "Österreich",
        "Deutschland",
        "Schweiz",
    ],
    plzList: [],
    zipListAustria: [],
    zipListGermany: [],
    zipListSwitzerland: [],
    edmData: [],

    data: {
      gln: "",
      uid: "",
      fn: "",
      name: "",
      street: "",
      streetNo: "",
      additional: "",
      zip: "",
      city: "",
      country: "Österreich",
      phone: "+43",
      url: "https://",
    },

    rules: {
      required: value => !!value || 'Erforderlich',
      counter: value => value.length <= 20 || 'Maximal 20 Zeichen',
      minLength: value => value.length > 7 || 'Mindestens 8 Zeichen',
      UN: value => value.length == 4 || 'Genau 4 Ziffern',
      zip: value => value.length <= 5 || 'PLZ maximal 5-stellig',
      zip2: value => {
        const pattern = /^[0-9]{4,5}$/
        return pattern.test(value) || 'PLZ darf nur 4-5 Ziffern enthalten'
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email-Adresse erforderlich'
      },
      max500: value => value.length <= 500 || 'Max. 500 Zeichen',
      max4: v => v.length <= 4 || 'Max. 4 Ziffern',
      gln: v => v.length == 13 || v.length == 0 || '13-stellige GLN',
    },

    initializedEDM: false,
    initializedZipAT: false,
    initializedZipDE: false,
    initializedZipCH: false,

  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    initializeBaseData() {
      if(this.initializedEDM && this.initializedZipAT && this.initializedZipDE && this.initializedZipCH) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
        setTimeout(()=>{
          this.show0 = true;
        }, 500);
        return true;
      }
      return false;
    },
    validateSelection() {
      return (this.isWasteDisposer || this.isWasteCollector || this.isWasteCreator || this.isWasteConsultant || this.isConverter || this.isSupplier || this.isTransport);
    },
    ...mapState(wasticsStore, ["keycloak"]),
  },
  methods: {
    showDetails() {
      this.show0 = false;
      setTimeout(()=>{
        this.show1 = true;
        this.showData0();
      }, 500);
    },
    showChoice() {
      this.show1 = false;
      this.data0 = false;
      this.data1 = false;
      this.data2 = false;
      this.data3 = false;
      this.data4 = false;
      setTimeout(()=>{
        this.show0 = true;
      }, 500);
    },

    showData0() {
      this.data1 = false;
      this.data2 = false;
      this.data3 = false;
      this.data4 = false;
      setTimeout(()=>{
        this.data0 = true;
      }, 500);
    },
    showData1() {
      if(this.data.gln.length == 13) {
        this.glnLoading = true;
        let searchCompany = this.edmData.find(el => el["Personen-GLN (Sammler/Behandler)"] === this.data.gln);
        if(searchCompany) {
          this.data.name = searchCompany["Name (Sammler/Behandler)"];
          this.data.street = searchCompany["Sitz-Strasse"];
          this.data.city = searchCompany["Sitz-Ort"];
          this.data.zip = searchCompany["Sitz-PLZ"];
          this.data0 = false;
          this.data1 = false;
          this.data2 = false;
          this.data3 = false;
          this.data4 = false;
          setTimeout(()=>{
            this.data1 = true;
          }, 500);
        } else {
          /* Selenium GLN Lookup call
          *  === deleted ===
          * */
          this.data0 = false;
          this.data1 = false;
          this.data2 = false;
          this.data3 = false;
          this.data4 = false;
          setTimeout(()=>{
            this.data1 = true;
          }, 500);
        }
      } else {
        this.data0 = false;
        this.data1 = false;
        this.data2 = false;
        this.data3 = false;
        this.data4 = false;
        setTimeout(()=>{
          this.data1 = true;
        }, 500);
      }
    },
    showData2() {
      this.data0 = false;
      this.data1 = false;
      this.data2 = false;
      this.data3 = false;
      this.data4 = false;
      setTimeout(()=>{
        if(this.data.uid.length >= 3) {
          if(this.data.uid.startsWith("AT")) {
            this.data.country = "Österreich";
            this.data3 = true;
          }
          if(this.data.uid.startsWith("CH")) {
            this.data.country = "Schweiz";
            this.data3 = true;
          }
          if(this.data.uid.startsWith("DE")) {
            this.data.country = "Deutschland";
            this.data3 = true;
          }
          if (this.data3 == false) {
            this.data2 = true;
          }
        } else {
          this.data2 = true;
        }
      }, 500);
    },
    showData3() {
      this.data0 = false;
      this.data1 = false;
      this.data2 = false;
      this.data3 = false;
      this.data4 = false;
      setTimeout(()=>{
        this.data3 = true;
      }, 500);
    },
    showData4() {
      let data = {
        company: this.data,
        isWasteCreator: this.isWasteCreator,
        isWasteCollector: this.isWasteCollector,
        isWasteDisposer: this.isWasteDisposer,
        isWasteConsultant: this.isWasteConsultant,
        isConverter: this.isConverter,
        isSupplier: this.isSupplier,
        isTransport: this.isTransport,
      };
      this.finishRegistration(data, this.registrationSuccess, this.registrationFailure);
    },
    registrationSuccess() {
      console.log("All good");
      this.data0 = false;
      this.data1 = false;
      this.data2 = false;
      this.data3 = false;
      this.data4 = false;
      setTimeout(()=>{
        this.data4 = true;
      }, 500);
    },
    registrationFailure(err) {
      console.log("Something not working here");
      console.log(err);
    },

    onCountryChange() {
      switch (this.data.country) {
        case "Deutschland":
          this.plzList = this.zipListGermany;
          if(this.data.phone.length <= 3) {
            this.data.phone = "+49"
          }
          break;
        case "Österreich":
          this.plzList = this.zipListAustria;
          if(this.data.phone.length <= 3) {
            this.data.phone = "+43"
          }
          break;
        case "Schweiz":
          this.plzList = this.zipListSwitzerland;
          if(this.data.phone.length <= 3) {
            this.data.phone = "+41"
          }
          break;
      }
    },

    checkPLZ() {
      if (this.data.country === "Österreich") {
        if (this.data.zip.length == 4) {
          let zip = this.plzList.find(el => el.plz === Number(this.data.zip));
          this.data.city = zip.ort;
        }
      }
      if (this.data.country === "Deutschland") {
        if (this.data.zip.length == 5) {
          let zip = this.plzList.find(el => el.zipcode === this.data.zip);
          this.data.city = zip.city;
        }
      }
      if (this.data.country === "Schweiz") {
        if (this.data.zip.length == 4) {
          let zip = this.plzList.find(el => el.plz === this.data.zip);
          this.data.city = zip.ort;
        }
      }
    },

    checkGLN() {
      axios.post(this.baseUrl + "/v1/checkGLN", {gln: this.gln}, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(res => {console.log(res.data); this.fname = res.data.name})
          .catch(err => {console.log(err)});
    },
    getEDMData() {
      axios.get(this.baseUrl + "/v1/edm/registered", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res)=>{
            this.edmData = res.data.registered;
            this.initializedEDM = true;
          })
          .catch(err => {console.log(err)});
    },

    ...mapActions(wasticsStore, ["finishRegistration"]),
  },
  created() {
    axios.get("https://data.rtr.at/api/v1/tables/plz.json")
    .then((res) => {
      this.zipListAustria = res.data.data;
      this.plzList = this.zipListAustria;
      this.initializedZipAT = true;
    })
    .catch(err => {
      console.log(err);
    });
    axios.get(this.baseUrl + "/v1/zip/germany", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
        .then((res) => {
          this.zipListGermany = res.data;
          this.initializedZipDE = true;
        })
        .catch(err => {
          console.log(err);
        });
    axios.get(this.baseUrl + "/v1/zip/switzerland", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
        .then((res) => {
          this.zipListSwitzerland = res.data;
          this.initializedZipCH = true;
        })
        .catch(err => {
          console.log(err);
        });
    this.getEDMData();
  }
}
</script>

<style scoped>

</style>