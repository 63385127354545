<template>
<v-container>
  <v-card>
    <v-card-title>Wastics Stammdaten</v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="companyData.name" label="Name" outlined></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="companyData.street" label="Straße" outlined></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="companyData.streetNo" label="Nummer" outlined></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="companyData.zip" label="PLZ" outlined></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="companyData.city" label="Stadt" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="companyData.fn" label="FN" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="companyData.uid" label="UID" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="companyData.phone" label="Telefon" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="companyData.mail" label="Mail" outlined></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined>Speichern</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
export default {
  name: "Cms",
  data: () => ({
    companyData: {},
  }),
}
</script>

<style scoped>

</style>