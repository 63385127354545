import axios from "axios";
import {wasticsStore} from "@/store";
import Config from "@/apis/Config";

export default class JsonDataApi {
    http;
    store;
    constructor() {
        this.store = wasticsStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.getToken()}`
            }
        });
    }
    getAll() {
        return this.http.get("/v1/jsonData")
    }

    add(data) {
        return this.http.post("/v1/jsonData", data)
    }

    update(data) {
        return this.http.put("/v1/jsonData", data)
    }

    delete(id) {
        return this.http.delete("/v1/jsonData/" + id)
    }
}