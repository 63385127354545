var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',{staticClass:"rounded-xl"},[_c('v-card-title',[_vm._v(" Rechnungen "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2 position",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_vm._v(" Weitere Details ")]),_c('v-list-item',[_vm._v(" Bearbeiten ")]),_c('v-list-item',[_vm._v(" PDF Herunterladen ")]),_c('v-list-item',[_vm._v(" Weiterleiten ")])],1)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }