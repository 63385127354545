<template>
  <v-container>
    <v-col>
    <v-card class="rounded-xl">
      <v-toolbar flat>
        <v-toolbar-title>Deine Angebote</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-col>
        <v-row align-content="center" justify="center">
          <v-hover v-slot="{ hover }">
            <v-card class="marging rounded-l-xl" height="50" width="170" align="center" style="cursor: pointer" :elevation="hover ? 9 : 3"
                :class="{ 'on-hover': hover }" :color="filter_eco" dark>
              <h3 class="text">
                ökologisch
              </h3>
            </v-card>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-card class="marging" height="50" width="170" align="center" style="cursor: pointer" :elevation="hover ? 9 : 3"
                    :class="{ 'on-hover': hover }" :color="filter_cost" dark>
              <h3 class="text">
                kosteneffizient
              </h3>
            </v-card>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-card class="marging rounded-r-xl" height="50" width="170" align="center"  style="cursor: pointer" :elevation="hover ? 9 : 3 "
                    :class="{ 'on-hover': hover }" :color="filter_fast" dark>
              <h3 class="text">
                schnell
              </h3>
            </v-card>
          </v-hover>
        </v-row>
        </v-col>
      </v-card-text>
      <v-col>
        <v-col>
          <v-expansion-panels
          multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="center">
                Weitere Filter anwenden
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col>
                  <v-row class="align-content-center justify-center">
                    <v-text-field
                        class="size"
                        label="Preis: von"
                        outlined
                    ></v-text-field>
                    <h1>-</h1>
                    <v-text-field
                        class="size"
                        label="Preis: bis"
                        outlined
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-row class="align-content-center justify-center">
                  <v-text-field
                      label="Ecoscore"
                      outlined
                      class="size"
                  ></v-text-field>
                </v-row>
                <v-row class="align-content-center justify-center">
                  <v-combobox
                      v-model="select"
                      :items="items"
                      label="Verfahren"
                      multiple
                      outlined
                      dense
                      class="size"
                  ></v-combobox>
                </v-row>
                <v-col>
                  <v-row class="justify-center align-content-center" >
                    <v-btn color="primary" dark rounded>
                      Anwenden
                    </v-btn>
                  </v-row>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-col>
    </v-card>
    </v-col>

    <v-col>
      <v-card class="rounded-xl">
        <v-card-text v-if="orders.length == 0">Aktuell sind keine Daten vorhanden</v-card-text>
        <v-card-text v-else>
          <v-col>
            <v-expansion-panels
                v-model="panel"
                multiple
            >
              <v-expansion-panel
                  v-for="order in orders"
                  :key="order.id"
              >
                <v-expansion-panel-header>
                  <v-card-text>
                    <v-row>
                      <h3 class="margin coloreco"> ecoScore <v-chip :color="getColor(order.waste.ecoscore)" dark>{{order.waste.ecoscore}}</v-chip> </h3>
                      <h3 class="colortext">|</h3>
                      <h3 class="margin2 colortext"> Ertrag </h3>
                      <h3 class="margin2 coloreco"> {{order.waste.price}} </h3>
                      <h3 class="colortext">|</h3>
                      <h3 class="margin coloreco"> {{order.waste.verfahren}}</h3>
                      <h3 class="colortext">|</h3>
                      <h3 class="margin colortext"> {{order.created | orderDate}}</h3>
                    </v-row>
                  </v-card-text>

                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <v-card-text align="center">
                    <v-col>
                      <v-col>
                        <h2>Allgemeine Daten</h2>
                      </v-col>
                      <v-row align="center" justify="center">
                        <h4>
                          <div class="a colortext">
                            SN: {{order.waste.SN}}<br>
                            Menge: {{order.waste.quantity}} {{order.waste.unit}}<br>
                            Bezeichnung: {{order.waste.description}}
                          </div>
                        </h4>

                        <h4>
                          <div class="a colortext">
                            Abholstandort: {{order.waste.pickupLocation.zip}} {{order.waste.pickupLocation.city}}, {{order.waste.pickupLocation.street}}<br>
                            Datum der Entsorgung: {{order.waste.datumEntsorgen}}<br>
                            Datum des Angebots: {{order.waste.datumAngebot}}<br>
                          </div>
                        </h4>
                      </v-row>
                    </v-col>

                    <v-row class="space align-content-center justify-center">
                      <h3 class="pos">ecoScore</h3>
                      <v-tooltip bottom >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="orange" v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span>Information Ecoscore</span>
                      </v-tooltip>
                    </v-row>

                    <v-col >
                      <v-img src="../assets/Scala.png" width="500">
                        <v-icon v-if="order.waste.status3 == 'green'" large color="purple" class="markerlocation" style="position: absolute; left: 50px;">
                          mdi-radiobox-marked
                        </v-icon>
                        <v-icon v-else-if="order.waste.status3 == 'yellow'" large color="purple" class="markerlocation2" style="position: absolute; left: 50px;">
                          mdi-radiobox-marked
                        </v-icon>
                      </v-img>
                    </v-col>
                    <v-col>

                      <v-row class="space align-content-center justify-center">
                        <h3 class="pos">CircularityScore</h3>
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="orange" v-bind="attrs" v-on="on">
                              mdi-information
                            </v-icon>
                          </template>
                          <span>Information CircularityScore</span>
                        </v-tooltip>
                      </v-row>



                      <v-col>
                        <v-img v-if="order.waste.status3 == 'green'" src="../assets/circularityScore.jpg" width="600"></v-img>
                        <v-img v-else-if="order.waste.status3 == 'yellow'" src="../assets/circularityScore2.jpg" width="600"></v-img>
                      </v-col>
                      <v-col>


                    </v-col>
                    </v-col>
                  </v-card-text>
                  <v-card-text>

                    <h3 align="center" class="pos2">Preis</h3>
                    <v-row class="align-content-center justify-center">
                      <div>Entsorgungskosten</div>
                      <v-divider class="divider"></v-divider><br>
                      <div>{{order.waste.entsorgungskosten}}</div>
                    </v-row>

                    <v-row class="align-content-center justify-center">
                      <div>Transport & Logistik</div>
                      <v-divider class="divider"></v-divider><br>
                      <div>{{order.waste.transportlogistik}}</div>
                    </v-row>

                    <v-row class="align-content-center justify-center">
                      <div>Sonstige Kosten</div>
                      <v-divider class="divider"></v-divider><br>
                      <div>{{order.waste.sonskosten}}</div>
                    </v-row>
                  </v-card-text>

                  <h2 align="center" class="pos coloreco"> {{order.waste.gesamtkosten}}</h2>

                  <h4 align="center" class="pos">Gesamtertrag</h4>
                  <v-row class="justify-center align-content-center">
                  <v-chip class="pos3 light-green" dark><h2 > {{order.waste.price}}</h2></v-chip>
                  </v-row>

                  <v-dialog
                      v-model="dialog"
                      width="500"
                      class="align-content-center justify-center"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-col>
                        <v-row class="align-content-center justify-center">
                          <v-btn color="primary" dark v-bind="attrs" v-on="on" rounded>
                            Angebot auswählen
                          </v-btn>
                        </v-row>
                      </v-col>
                    </template>

                    <v-card>
                      <v-card-title class="align-content-center justify-center">
                        Deine Auswahl<br>
                      </v-card-title>
                      <v-divider></v-divider><br>
                      <v-card-text align="center">
                        <h2>{{order.waste.verfahren}}</h2><br>
                        <v-divider></v-divider><br>
                        <h3>Abholung am</h3><br>
                        <h3>{{order.waste.abholdatum}}</h3><br>
                        <v-divider></v-divider><br>
                        <h2>{{order.waste.price}}</h2><br>
                        <v-divider></v-divider><br>
                        <h2 class="color">ecoScore <v-chip :color="getColor(order.waste.ecoscore)" dark>{{order.waste.ecoscore}}</v-chip></h2><br>
                      </v-card-text>

                      <v-card-actions>
                        <v-col>
                          <v-btn @click="dialog = false" rounded>
                            Abbrechen
                          </v-btn>
                        </v-col>

                        <v-col align="right">
                          <v-dialog
                              v-model="erfolg"
                              width="500"
                              class="align-content-center justify-center"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="primary" @click="dialog = false, erfolg = true"  dark v-bind="attrs" v-on="on" rounded>
                                Bestätigen
                              </v-btn>
                            </template>
                            <v-card>
                              <v-col>
                                <v-card-text align="center">
                                  <v-icon x-large color="green">mdi-checkbox-marked-circle-outline</v-icon>
                                  <h3>Vielen Dank. Auftrag wurde vermittelt.</h3>
                                </v-card-text>
                              </v-col>
                              <v-card-actions>
                                <v-col align="center">
                                  <v-btn color="primary"
                                         @click="erfolg = false"
                                         dark
                                         rounded
                                  >
                                    Schließen
                                  </v-btn>
                                </v-col>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>
<script>
export default {
  name: "Order",
  data: () => ({
    filter_eco: 'purple',
    filter_cost: 'grey',
    filter_fast: 'grey',
    dialog: false,
    erfolg: false,
    panel: [],
    orders: [
      {
        id: '0',
        created: "2022-06-01T15:00:00",
        waste: {
          created: "2022-05-28T15:00:00",
          description: "Kunststofffolien",
          datumAngebot:"01.06.2022",
          datumEntsorgen:"17.06.2022",
          ecoscore: "B",
          verfahren: "Extrusion",
          price: "8250€",
          status3:'green',
          entsorgungskosten: '+75€/t',
          transportlogistik: '-20€/t',
          sonskosten: '-20€',
          gesamtkosten:'35€/t',
          SN: "57119",
          quantity: "150",
          unit: "t",
          pickupLocation: {
            street: "Ringofenstraße 7",
            zip: "2333",
            city: "Leopoldsdorf"
          },
        },
        state: 'created',
      },
      {
        id: '1',
        created: "2022-05-28T15:00:00",
        waste: {
          created: "2022-05-28T15:00:00",
          description: "Kunststofffolien",
          datumAngebot:"01.06.2022",
          datumEntsorgen:"17.06.2022",
          ecoscore: "D",
          verfahren: "Pyrolyse",
          price: "7500€",
          status3:'yellow',
          entsorgungskosten: '+100€/t',
          transportlogistik: '-30€/t',
          sonskosten: '-20€',
          gesamtkosten:'50€/t',
          SN: "57119",
          quantity: "150",
          unit: "t",
          pickupLocation: {
            street: "Ringofenstraße 7",
            zip: "2333",
            city: "Leopoldsdorf"
          },
        },
        state: 'created',
      },

      {
        id: '2',
        created: "2022-05-28T15:00:00",
        waste: {
          created: "2022-05-28T15:00:00",
          description: "Kunststofffolien",
          abholdatum:"01.07.2022",
          datumAngebot:"01.07.2022",
          datumEntsorgen:"01.07.2022",
          ecoscore: "F",
          verfahren: "Verbrennung",
          price: "2000€",
          status3:'',
          entsorgungskosten: '300€',
          transportlogistik: '500€',
          sonskosten: '200€',
          gesamtkosten:'1000€',
          SN: "57119",
          quantity: "150",
          unit: "t",
          pickupLocation: {
            street: "Ringofenstraße 7",
            zip: "2333",
            city: "Leopoldsdorf"
          },
        },
        state: 'created',
      },
    ],
    select: ['Vuetify', 'Pyrolyse'],
    items: [
      'Pyrolyse',
      'Design',
      'Vue',
      'Vuetify',
    ],
  }),
  methods: {
    getColor (ecoscore) {
      if (ecoscore == 'A') return 'green'
      else if (ecoscore == 'B') return 'green'
      else if (ecoscore == 'C') return 'green'
      else if (ecoscore == 'D') return 'yellow'
      else if (ecoscore == 'E') return 'orange'
      else if (ecoscore == 'F') return 'orange'
      else if (ecoscore == 'G') return 'red'
    },
  },
  filters: {
    orderDate(value) {
      if (!value) return ''
      value = value.toString()
      let date = new Date(value);
      return new Intl.DateTimeFormat('de-DE', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date);
    },
  },
}
</script>

<style scoped>

.center {
  align-content: center;
  justify-content: center;
}

.text {
  margin-top: 12px;
}

.marging {
  margin-left: 15px;
  margin-right: 15px;

}

.margin {
  margin-left: 30px;
  margin-right: 30px;
}

.pos {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pos2 {
  margin-bottom: 30px;
}

.pos3 {
  margin-bottom: 30px;
}

.a {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

}

.markerlocation {
  margin-left: 20px;
  margin-top: 10px;
}

.markerlocation2 {
  margin-left: 110px;
  margin-top: 10px;
}

.coloreco {
  color: purple;
}

.colortext {
  color: gray;
}


.divider {
  max-width: 500px;
}

.color {
  color: purple;
}

</style>