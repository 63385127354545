<template>
<v-container>
  <v-card>
    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
  </v-card>
  <v-card class="pb-5">
    <v-card-title>Uploads</v-card-title>
    <v-card-text>
      <v-data-table
          :items="uploads"
          :headers="headers"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="previewUpload(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
              small
              @click="deleteUpload(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="getUploads" color="primary" rounded>Aktualisieren</v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog fullscreen v-model="detailDialog">
    <v-card class="pb-5">
      <v-card-title>Details</v-card-title>
      <v-card-text>
        <span>{{previewItem.originalFilename}}</span><br>
        <span v-if="previewItem.thumbnail !== null">{{previewItem.thumbnail}}</span>
        <span v-else>null</span>
        <br>
        <img v-if="previewItem.thumbnail !== null" :src="baseUrl + '/v1/thumbnail/' + previewItem.id" style="max-width: 80vw;">
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePreview" color="primary" rounded>Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from "axios";

export default {
  name: "UploadManager.vue",
  components: {
    vueDropzone: vue2Dropzone
  },
  data: () => ({
    dropzoneOptions: {
      //url: 'https://httpbin.org/post',
      url: 'http://localhost:30303/v1/upload',
      thumbnailWidth: 150,
      maxFilesize: 25.5,
      //headers: { "My-Awesome-Header": "header value" },
    },
    uploads: [],
    headers: [
      {text: "Dateiname", value: "originalFilename"},
      {text: "Path", value: "uploadedFilename"},
      {text: "Aktion", value: "actions", sortable: false},
    ],
    detailDialog: false,
    previewItem: {},
    defaultPreviewItem: {
      originalFilename: "",
      uploadedFilename: "",
      thumbnail: "/img/wastics-favicon.png",
    },
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
  },
  methods: {
    deleteUpload(upload) {
      axios.delete(this.baseUrl + "/v1/uploads/" + upload.id)
      .then(()=>{this.getUploads()});
    },
    getUploads() {
      axios.get(this.baseUrl + "/v1/uploads")
          .then((res)=>{
            this.uploads = res.data;
          });
    },
    previewUpload(file) {
      this.previewItem = Object.assign({}, file);
      this.detailDialog = true;
    },
    closePreview() {
      this.detailDialog = false;
      this.previewItem = Object.assign({}, this.defaultPreviewItem);
    },
  },
  created() {
    this.getUploads();
  }
}
</script>

<style scoped>

</style>