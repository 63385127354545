var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user.role == 'manager')?_c('v-container',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.orders,"headers":_vm.headers,"expanded":_vm.expanded,"single-expand":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Auftragsmanager")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Neuer Auftrag ")])]}}],null,false,4127515561),model:{value:(_vm.orderDialog),callback:function ($$v) {_vm.orderDialog=$$v},expression:"orderDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("XXX")]),_c('v-card-text',[_c('v-form')],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.orderDialog = false}}},[_vm._v("Abbrechen")])],1)],1)],1)],1)]},proxy:true},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("simpleDate")(item.created))+" ")]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.lookupUser(item.userId))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteOrder(item.id)}}},[_vm._v("mdi-delete")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"exp-row px-2",attrs:{"colspan":headers.length}},[(item.customerData)?_c('p',[_vm._v(_vm._s(item.customerData.firstname)+" "+_vm._s(item.customerData.lastname))]):_vm._e(),(item.customerData)?_c('p',[_vm._v("💻 "),_c('a',{attrs:{"href":("mailto:" + (item.customerData.email))}},[_vm._v(_vm._s(item.customerData.email))])]):_vm._e(),(item.customerData)?_c('p',[_vm._v("📱 "),_c('a',{attrs:{"href":("tel:" + (item.customerData.phone))}},[_vm._v(_vm._s(item.customerData.phone))])]):_vm._e()])]),_vm._l((item.wasteRequests),function(waste,i){return _c('tr',{key:i},[_c('td',{staticClass:"exp-row px-2",attrs:{"colspan":headers.length}},[_c('p',[_c('v-badge',{attrs:{"content":("" + (i + 1))}},[_c('v-icon',[_vm._v("mdi-format-list-checkbox")])],1),_vm._v(" "+_vm._s(waste.sn))],1),_c('p',[_vm._v(_vm._s(waste.description))]),_c('p',[_vm._v(_vm._s(waste.pickupLocation.zip)+" "+_vm._s(waste.pickupLocation.city)+", "+_vm._s(waste.pickupLocation.street))])])])})]}}],null,false,765895740)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }