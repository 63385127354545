<template>
  <v-container>
    <v-card>
      <div id="mapContainer" style="height: 50vh; width: 100%;"></div>
      <v-progress-linear
          :indeterminate="loadCurrentPosition"
          color="primary"
          v-if="useCurrentPosition && loadCurrentPosition"
      ></v-progress-linear>
      <v-subheader v-if="useCurrentPosition && loadCurrentPosition">Aktueller Standort wird ermittelt.</v-subheader>
      <v-subheader v-else>Standort auf Karte wählen oder eingeben.</v-subheader>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn icon outlined color="primary" @click="getLocation" :disabled="loadCurrentPosition && notSupportedGLS">
              <v-icon color="primary" v-if="useCurrentPosition">mdi-map-marker</v-icon>
              <v-icon v-else color="#c0c0c0">mdi-map-marker-off</v-icon>
            </v-btn>
            <span v-if="notSupportedGLS">
              &nbsp;Standort blockiert oder nicht unterstützt
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" v-bind="attrs" v-on="on" right class="middle">
                    mdi-information
                  </v-icon>
                </template>
                <span>
                  Dein Browser unterstützt das Abrufen deines aktuellen Standorts nicht oder du hast diese Einstellung nicht aktiviert.
                </span>
              </v-tooltip>
            </span>
            <span v-else @click="getLocation" style="cursor: pointer;">
              &nbsp;Meinen aktuellen Standort verwenden
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" v-bind="attrs" v-on="on" right class="middle">
                    mdi-information
                  </v-icon>
                </template>
                <span>
                  Dein Browser kann deinen aktuellen Standort ermitteln, dann sparst du dir ein paar Eingaben.
                </span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" rounded outlined @click="openSearchDialog" :disabled="searchDialog"><v-icon>mdi-magnify</v-icon> Suchen</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field v-model="street" label="Straße" rounded filled :disabled="loadCurrentPosition"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field label="Nummer" rounded filled v-model="streetNo"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field label="Tür" rounded filled v-model="door"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="zip" label="PLZ" rounded filled :disabled="loadCurrentPosition"></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="city" label="Stadt" rounded filled :disabled="loadCurrentPosition"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select label="Land *" rounded filled v-model="country" :items="countries"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="lat" disabled outlined label="Lat"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="lng" disabled outlined label="Lng"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn rounded outlined @click="openTimetableDialog"><v-icon>mdi-timetable</v-icon> Ladezeiten hinzufügen</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="parentCancel" rounded>Abbrechen</v-btn>
        <v-btn @click="openLabelDialog" color="primary" rounded>Speichern</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="labelDialog">
      <v-card>
        <v-card-title>Speichern</v-card-title>
        <v-card-text>
          <p>Unter welchem Namen soll dieser Standort gespeichert werden?</p>
          <v-row>
            <v-col cols="10" md="8">
              <v-text-field v-model="label" label="Bezeichnung" rounded filled></v-text-field>
            </v-col>
            <v-col cols="2" md="4">
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" v-bind="attrs" v-on="on" right class="middle">
                    mdi-information
                  </v-icon>
                </template>
                <span>
                Unter dieser Bezeichnung wird der Standort zusammenfassend gespeichert und kann in zukünftigen Anfragen verwendet werden.
              </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeLabelDialog" rounded>Zurück</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="parentClick" color="primary" rounded>Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="searchDialog" fullscreen>
      <v-card>
        <v-card-title>Suche</v-card-title>
        <v-slide-y-transition>
          <div v-show="search0">
            <v-card-text>
              <v-text-field label="Suche" v-model="searchString" rounded outlined v-on:keyup.enter="searchLocation"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="closeSearchDialog" rounded outlined>Abbrechen</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="searchLocation" color="primary" rounded outlined>Suchen</v-btn>
            </v-card-actions>
          </div>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <div v-show="search1">
            <v-card-text>
              <div v-if="searchResult.length == 0">
                Suche ergab keine Treffer.
              </div>
              <v-row v-for="result in searchResult" :key="result.place_id">
                <v-col>
                  {{result.display_name}}
                </v-col>
                <v-col>
                  <v-btn rounded outlined @click="setLocation(result.lat, result.lon, result.display_name)">Übernehmen</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="closeSearchDialog" rounded outlined>Abbrechen</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="resetSearch" color="primary" rounded outlined>Neue Suche</v-btn>
            </v-card-actions>
          </div>
        </v-slide-y-transition>
      </v-card>
    </v-dialog>
    <v-dialog v-model="timetableDialog" fullscreen>
      <v-card>
        <v-card-title>Ladezeiten</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>Montag</v-col>
            <v-col>
              <v-text-field label="von" v-model="timetable.monday_from" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="bis" v-model="timetable.monday_to" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Dienstag</v-col>
            <v-col>
              <v-text-field label="von" v-model="timetable.tuesday_from" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="bis" v-model="timetable.tuesday_to" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Mittwoch</v-col>
            <v-col>
              <v-text-field label="von" v-model="timetable.wednesday_from" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="bis" v-model="timetable.wednesday_to" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Donnerstag</v-col>
            <v-col>
              <v-text-field label="von" v-model="timetable.thursday_from" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="bis" v-model="timetable.thursday_to" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Freitag</v-col>
            <v-col>
              <v-text-field label="von" v-model="timetable.friday_from" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="bis" v-model="timetable.friday_to" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Samstag</v-col>
            <v-col>
              <v-text-field label="von" v-model="timetable.saturday_from" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="bis" v-model="timetable.saturday_to" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Sonntag</v-col>
            <v-col>
              <v-text-field label="von" v-model="timetable.sunday_from" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="bis" v-model="timetable.sunday_to" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeTimetableDialog" rounded outlined>Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveTimetable" color="primary" rounded outlined>Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {Icon} from "leaflet";
import L from "leaflet";
import axios from "axios";
import {mapActions} from "pinia/dist/pinia.esm-browser";
import {wasticsStore} from "@/store";
import {mapState} from "pinia/dist/pinia";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: "LocationMap",
  data: () => ({
    test: false,
    useCurrentPosition: false,
    labelDialog: false,
    searchDialog: false,
    timetableDialog: false,
    search0: true,
    search1: false,
    searchString: '',
    searchResult: [],
    //location: [48.1767414, 16.3313324],
    location: [48.2084114, 16.3734707],
    map: null,
    label: "",
    street: "",
    streetNo: "",
    door: "",
    city: "",
    zip: "",
    country: "",
    countries: ["Österreich", "Deutschland", "Schweiz"],
    marker: null,
    lat: 48.1767414,
    lng: 16.3313324,
    position: null,
    loadCurrentPosition: false,
    notSupportedGLS: false,
    emptyTimetable: {
      monday_from: "",
      monday_to: "",
      tuesday_from: "",
      tuesday_to: "",
      wednesday_from: "",
      wednesday_to: "",
      thursday_from: "",
      thursday_to: "",
      friday_from: "",
      friday_to: "",
      saturday_from: "",
      saturday_to: "",
      sunday_from: "",
      sunday_to: "",
    },
    defaultTimetable: {
      monday_from: "8:00",
      monday_to: "17:00",
      tuesday_from: "8:00",
      tuesday_to: "17:00",
      wednesday_from: "8:00",
      wednesday_to: "17:00",
      thursday_from: "8:00",
      thursday_to: "17:00",
      friday_from: "8:00",
      friday_to: "17:00",
      saturday_from: "",
      saturday_to: "",
      sunday_from: "",
      sunday_to: "",
    },
    timetable: {},
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    logLayers() {
      this.map.eachLayer((layer) => {
        console.log(layer);
        if(layer._icon) {
          layer.remove();
        }
      });
    },
    setupLeaflet() {
      this.map = L.map("mapContainer").setView(this.location, 18);
      L.tileLayer(
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          {
            attribution: 'Wastics',
            maxZoom: 19,
          }
      ).addTo(this.map);
      this.map.on('click', this.onMapClick);
      setTimeout(()=>{
        this.map.invalidateSize();
      }, 500);
    },
    setMarker() {
      if(this.marker != null) {
        this.marker.remove();
      }
      this.marker = L.marker(this.location).addTo(this.map);
    },
    // eher Suche:
    getLatLon() {
      axios.get("https://nominatim.openstreetmap.org/search?format=json&street="+ encodeURIComponent(this.street + " " + this.streetNo) + "&city=" + encodeURIComponent(this.city) + "&postalcode=" + encodeURIComponent(this.zip))
          .then((res)=>{
            console.log(res.data);
            this.location = [];
            this.location.push(res.data[0].lat);
            this.location.push(res.data[0].lon);
            this.lat = res.data[0].lat;
            this.lng = res.data[0].lon;
            if(this.marker != null) {
              this.marker.remove();
            }
            this.setMarker();
            this.map.setView(this.location, 18);
          })
    },
    searchLocation() {
      this.search0 = false;
      axios.get("https://nominatim.openstreetmap.org/search.php?q="+encodeURIComponent(this.searchString)+"&format=jsonv2")
          .then((res)=> {
            console.log(res.data);
            this.searchResult = res.data;
            setTimeout(()=>{
              this.search1 = true;
            }, 300)
          })
    },
    resetSearch() {
      this.searchResult = [];
      this.searchString = "";
      this.search1 = false;
      setTimeout(()=>{
        this.search0 = true;
      }, 300)
    },
    setLocation(lat, lon, displayName) {
      this.city = "";
      this.zip = "";
      this.street = "";
      this.streetNo = "";
      this.door = "";
      this.country = "";
      this.label = displayName;
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + lat + "&lon=" + lon + "&zoom=18&addressdetails=1")
      .then((res)=> {
        console.log(res.data);
        if(res.data.address.city) {
          this.city = res.data.address.city;
        } else {
          if(res.data.address.town) {
            this.city = res.data.address.town;
          } else {
            this.city = res.data.address.state;
          }
        }
        if(res.data.address.postcode) {
          this.zip = res.data.address.postcode;
        }
        if (res.data.address.road) {
          this.street = res.data.address.road;
        }
        if (res.data.address.house_number) {
          this.streetNo = res.data.address.house_number;
        }
        if (res.data.address.country) {
          this.country = res.data.address.country;
        }
      })
      this.location = [];
      this.location.push(lat);
      this.location.push(lon);
      this.lat = lat;
      this.lng = lon;
      //this.lat = res.data[0].lat;
      //this.lng = res.data[0].lon;
      if(this.marker != null) {
        this.marker.remove();
      }
      this.setMarker();
      this.map.setView(this.location, 18);
      this.closeSearchDialog();
    },
    onMapClick(e) {
      console.log(e.latlng);
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + e.latlng.lat + "&lon=" + e.latlng.lng + "&zoom=18&addressdetails=1")
          .then((res)=>{
            console.log(res.data);
            if(res.data.address.city) {
              this.city = res.data.address.city;
            } else {
              if(res.data.address.town) {
                this.city = res.data.address.town;
              } else {
                this.city = res.data.address.state;
              }
            }
            if(res.data.address.postcode) {
              this.zip = res.data.address.postcode;
            }
            if (res.data.address.road) {
              this.street = res.data.address.road;
            }
            if (res.data.address.house_number) {
              this.streetNo = res.data.address.house_number;
            }
            if (res.data.address.country) {
              this.country = res.data.address.country;
            }
            this.location = [];
            this.location.push(e.latlng.lat);
            this.location.push(e.latlng.lng);
            this.lat = e.latlng.lat;
            this.lng = e.latlng.lng;
            if(this.marker != null) {
              this.marker.remove();
            }
            this.setMarker();
          })
    },
    getLocation() {
      this.loadCurrentPosition = true;
      this.useCurrentPosition = true;
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(this.savePosition, this.positionError, {timeout:10000});
      } else {
        console.log("not supported gls");
        this.notSupportedGLS = true;
        this.useCurrentPosition = false;
      }
    },
    positionError(error) {
      console.log(error.code);
      console.log(error.message);
      this.loadCurrentPosition = false;
    },
    savePosition(position) {
      console.log(position);
      this.position = position;
      this.location = [];
      this.location.push(position.coords.latitude);
      this.location.push(position.coords.longitude);
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.setMarker();
      this.map.setView(this.location, 18);
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + this.lat + "&lon=" + this.lng + "&zoom=18&addressdetails=1")
          .then((res)=> {
            console.log(res.data);
            if (res.data.address.city) {
              this.city = res.data.address.city;
            } else {
              if (res.data.address.town) {
                this.city = res.data.address.town;
              } else {
                this.city = res.data.address.state;
              }
            }
            if(res.data.address.postcode) {
              this.zip = res.data.address.postcode;
            }
            if (res.data.address.road) {
              this.street = res.data.address.road;
            }
            if (res.data.address.house_number) {
              this.streetNo = res.data.address.house_number;
            }
            if (res.data.address.country) {
              this.country = res.data.address.country;
            }
            this.label = this.street + " " + this.streetNo + ", " + this.city;
          });
      this.setMap(this.map);
      this.loadCurrentPosition = false;
    },
    parentCancel() {
      let result = {
        action: "cancel"
      };
      this.$emit('clicked', result);
    },
    parentClick() {
      let data = {
        label: this.label,
        street: this.street,
        streetNo: this.streetNo,
        door: this.door,
        zip: this.zip,
        city: this.city,
        country: this.country,
        timetable: this.timetable,
      };
      let result = {
        action: "save",
        data: data,
      };
      this.$emit('clicked', result);
    },
    closeLabelDialog() {
      this.labelDialog = false;
    },
    openLabelDialog() {
      this.labelDialog = true;
    },
    closeSearchDialog() {
      this.searchDialog = false;
      this.resetSearch();
    },
    openSearchDialog() {
      this.searchDialog = true;
    },
    closeTimetableDialog() {
      this.timetableDialog = false;
    },
    openTimetableDialog() {
      this.timetableDialog = true;
    },
    saveTimetable() {
      this.timetableDialog = false;
    },
    ...mapActions(wasticsStore, ["setMap"]),
  },
  mounted() {
    this.setupLeaflet();
    if ('geolocation' in navigator) {
      this.notSupportedGLS = false;
      if(this.useCurrentPosition) {
        this.getLocation();
      }
    } else {
      this.notSupportedGLS = true;
    }
    this.timetable = Object.assign({}, this.defaultTimetable);
    // console.log("LocationMap mounted")
  },
  updated() {
    // console.log("LocationMap updated")
  }
}
</script>

<style>
.leaflet-control-attribution {
  display: none !important;
}
</style>