<template>
  <v-col>
  <v-card class="rounded-xl elevation-2">
    <v-toolbar flat>
      <v-row>
    <v-card-title>
      Benutzer
    </v-card-title>
    <v-col>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          class="size"
      ></v-text-field>
    </v-col>
      <v-col align="right">
      <v-btn fab dark color="purple" small>
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-col>
      </v-row>
    </v-toolbar>
    <v-row
        class="pa-4"
        justify="space-between"
    >
      <v-col cols="5">
        <v-treeview
            :active.sync="active"
            :items="items"
            :load-children="fetchUsers"
            :open.sync="open"
            :search="search"
            activatable
            color="warning"
            transition
            open-on-click
            open-all
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="!item.children">
              mdi-account
            </v-icon>
          </template>
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col
          class="d-flex text-center"
      >
        <v-scroll-y-transition mode="out-in">
          <div
              v-if="!selected"
              class="text-h6 grey--text text--lighten-1 font-weight-light"
              style="align-self: center;"
          >
            Benutzer auswählen
          </div>
          <v-card
              v-else
              :key="selected.id"
              class="pt-6 mx-auto"
              flat
              max-width="400"
          >
            <v-card-text>
              <v-avatar
                  v-if="avatar"
                  size="88"
              >
                <v-img
                    :src="`https://avataaars.io/${avatar}`"
                    class="mb-6"
                ></v-img>
              </v-avatar>
              <h3 class="text-h5 mb-2">
                {{ selected.name }}
              </h3>
              <div class="blue--text mb-2">
                {{ selected.email }}
              </div>
              <div class="blue--text subheading font-weight-bold">
                {{ selected.username }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-row
                class="text-left"
                tag="v-card-text"
            >
              <v-col
                  class="text-right mr-4 mb-2"
                  tag="strong"
                  cols="5"
              >
                Company:
              </v-col>
              <v-col>{{ selected.company.name }}</v-col>
              <v-col
                  class="text-right mr-4 mb-2"
                  tag="strong"
                  cols="5"
              >
                Website:
              </v-col>
              <v-col>
                <a
                    :href="`//${selected.website}`"
                    target="_blank"
                >{{ selected.website }}</a>
              </v-col>
              <v-col
                  class="text-right mr-4 mb-2"
                  tag="strong"
                  cols="5"
              >
                Phone:
              </v-col>
              <v-col>{{ selected.phone }}</v-col>

              <v-row align="center">
                <v-col align="center" class="top">
                  <v-btn color="purple" dark>
                    Edit
                  </v-btn>
                </v-col>
                <v-col align="center" class="top">
                  <v-btn color="blue" dark>
                    Delete
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
  </v-col>
</template>



<style scoped>

.top{
  margin-top: 20px;
}

.size {
  width: 300px;
}

</style>





<script>
const avatars = [
  '?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban',
  '?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
  '?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
  '?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
  '?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly',
]

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    active: [],
    avatar: null,
    open: [],
    users: [],
    search: "",
  }),

  computed: {
    items () {
      return [
        {
          name: 'Benutzer',
          children: this.users,
        },
      ]
    },
    selected () {
      if (!this.active.length) return undefined

      const id = this.active[0]

      return this.users.find(user => user.id === id)
    },
  },

  watch: {
    selected: 'randomAvatar',
  },

  methods: {
    async fetchUsers (item) {
      // Remove in 6 months and say
      // you've made optimizations! :)
      await pause(1500)

      return fetch('https://jsonplaceholder.typicode.com/users')
          .then(res => res.json())
          .then(json => (item.children.push(...json)))
          .catch(err => console.warn(err))
    },
    randomAvatar () {
      this.avatar = avatars[Math.floor(Math.random() * avatars.length)]
    },
  },
}
</script>
