<template>
  <v-container>
    <v-card class="rounded-xl">
      <v-toolbar flat>
        <v-col>
          <v-toolbar-title>Anfragen</v-toolbar-title>
        </v-col>
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn to="/" color="purple" dark class="text--white button">
          Neue Anfrage
        </v-btn>

      </v-toolbar>
      <v-card-text v-if="orders.length == 0">Aktuell sind keine Daten vorhanden</v-card-text>
      <v-card-text v-else>
        <v-col>
          <v-expansion-panels
              v-model="panel"
              multiple
          >
            <v-expansion-panel
                v-for="order in orders"
                :key="order.id"
                :search="doSearch"
            >
              <v-expansion-panel-header>
                <v-card-text>
                  <h4>{{order.created | orderDate}} | {{order.waste.description}}
                    <v-row class="justify-end align-content-center">
                      <v-chip
                          :color="getColor(order.waste.status)"
                          dark
                      >
                        {{getStatus(order)}}
                      </v-chip>
                    </v-row>


                  </h4>
                </v-card-text>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text class="mb-3">
                  <v-row class="a">


                    SN: {{order.waste.SN}}<br>
                    Menge: {{order.waste.quantity}} {{order.waste.unit}}<br>

                    <div class="test">
                      User: {{order.waste.user}}<br>
                      Ort: {{order.waste.pickupLocation.zip}} {{order.waste.pickupLocation.city}}, {{order.waste.pickupLocation.street}}<br>
                    </div>

                  </v-row>
                  <br>
                  <br>
                  Status: {{getStatus(order)}}

                  <v-row class="align-content-center justify-center">
                    <v-btn v-if="order.waste.angebote != '0'" to="/angebote" class="button" color="purple" dark >
                      {{order.waste.angebote}} Angebote anzeigen
                    </v-btn>
                    <v-btn v-else class="button" color="grey" dark >
                      Derzeit Keine Angebote
                    </v-btn>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="mt-3">
                  <v-row class="a">


                    SN: 33333 - Altöl<br>
                    Menge: 330 t<br>

                    <div class="test">
                      User: {{order.waste.user}}<br>
                      Ort: {{order.waste.pickupLocation.zip}} {{order.waste.pickupLocation.city}}, {{order.waste.pickupLocation.street}}<br>
                    </div>

                  </v-row>
                  <br>
                  <br>
                  Status: 2 Angebote

                  <v-row class="align-content-center justify-center">
                    <v-btn v-if="order.waste.angebote != '0'" to="/angebote" class="button" color="purple" dark >
                      2 Angebote anzeigen
                    </v-btn>
                    <v-btn v-else class="button" color="grey" dark >
                      Derzeit Keine Angebote
                    </v-btn>
                  </v-row>
                </v-card-text>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Order",
  data: () => ({
    panel: [0],
    search: '',
    orders: [
      {
        id: '0',
        created: "2022-05-28T15:00:00",
        waste: {
          created: "2022-05-28T15:00:00",
          description: "Kunststofffolien",
          SN: "57119",
          quantity: "150",
          unit: "t",
          status: 'Angebote',
          status2:'3',
          angebote:'3',
          user:"Stefan Schott",
          pickupLocation: {
            street: "Ringofenstraße 7",
            zip: "2333",
            city: "Leopoldsdorf"
          },
        },
        state: 'created',
      },
      {
        id: '1',
        created: "2022-06-01T15:00:00",
        waste: {
          created: "2022-06-01T15:00:00",
          SN: "57128",
          description: "Gemischte Kunststoffabfälle",
          quantity: "320",
          unit: "t",
          status: 'Angebote',
          status2:'5',
          angebote:'5',
          user:"Samuel Heinrich",
          pickupLocation: {
            street: "Ringofenstraße 7",
            zip: "2333",
            city: "Leopoldsdorf"
          },
        },
        state: 'created',
      },
      {
        id: '2',
        created: "2022-06-03T15:00:00",
        waste: {
          created: "2022-06-03T15:00:00",
          SN: "57304",
          description: "Kunststoffemulsion",
          quantity: "120",
          unit: "l",
          status:'In Bearbeitung',
          status2:'0 Angebote',
          angebote:'0',
          user:"Matthias",
          pickupLocation: {
            street: "Ringofenstraße 7",
            zip: "2333",
            city: "Leopoldsdorf"
          },
        },
        state: 'created',
      },
      {
        id: '3',
        created: "2022-06-06T15:00:00",
        waste: {
          created: "2022-06-06T15:00:00",
          SN: "54102",
          description: "Altöl",
          quantity: "50",
          unit: "l",
          status:'In Bearbeitung',
          status2:'0 Angebote',
          angebote:'0',
          user:"Samuel Heinrich",
          pickupLocation: {
            street: "Ringofenstraße 7",
            zip: "2333",
            city: "Leopoldsdorf"
          },
        },
        state: 'created',
      },
      {
        id: '4',
        created: "2022-07-06T15:00:00",
        waste: {
          created: "2022-07-06T15:00:00",
          SN: "35502",
          description: "Metallschleifschlamm",
          quantity: "260",
          unit: "kg",
          status:'Offen',
          status2:'0 Angebote',
          angebote:'0',
          user:"Markus",
          pickupLocation: {
            street: "Industriestraße 2B",
            zip: "2326",
            city: "Maria Lanzendorf"
          },
        },
        state: 'created',
      },
      {
        id: '5',
        created: "2022-08-06T15:00:00",
        waste: {
          created: "2022-08-06T15:00:00",
          SN: "35301",
          description: "Stanz- und Zerpanungsabfälle",
          quantity: "420",
          unit: "kg",
          status:'Offen',
          status2:'0 Angebote',
          angebote:'0',
          user:"Ralf Weinrich",
          pickupLocation: {
            street: "Industriestraße 2B",
            zip: "2326",
            city: "Maria Lanzendorf"
          },
        },
        state: 'created',
      },
    ],
  }),
  methods: {
    doSearch() {

    },
    getColor (status) {
      if (status == 'Offen') return 'red'
      else if (status == 'In Bearbeitung') return 'orange'
      else if (status == 'Angebote') return 'green'
      else return 'purple'
    },
    getColor2 (status2) {
      if (status2 == '0 Angebote') return 'grey'
      else return 'purple'
    },
    getStatus(order) {
      if(order.waste.status == 'Angebote') {
        return order.waste.status2 + ' Angebote';
      } else {
        if(order.waste.status == 'In Bearbeitung') {
          return "Warte auf Angebote";
        }
        return order.waste.status;
      }
    },
  },
  filters: {
    orderDate(value) {
      if (!value) return ''
      value = value.toString()
      let date = new Date(value);
      return new Intl.DateTimeFormat('de-DE', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date);
    },
  },
}
</script>

<style scoped>

.button {
  border-radius: 20px;
  align-content: center;
  justify-content: center;
}

.test {
  margin-left: 100px;
}

.a {
  margin-left: 5px;
}

</style>