<template>
  <v-sheet
      class="mx-auto"
      elevation="8"
      max-width="800"
  >
    <v-slide-group
        v-model="selectedWasteGroup"
        class="pa-4"
        show-arrows
    >
      <v-slide-item
          v-for="(n, i) in groups"
          :key="n"
          v-slot="{ active, toggle }"
      >
        <v-card
            :color="active ? 'primary' : groups[i].color"
            class="ma-4"
            height="200"
            width="150"
            @click="toggle"
            style="overflow-wrap: normal !important;"
        >
          <!-- style="background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));" -->
          <v-img :src="n.img" height="200">
            <v-card-title class="slider-content text-size-m">{{n.title}}</v-card-title>
            <!-- <v-card-text class="slider-content">
              {{n.img}}
            </v-card-text> -->
            <v-card-actions>
              <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
              >
                <v-scale-transition>
                  <v-icon
                      v-if="active"
                      color="white"
                      size="48"
                      v-text="'mdi-close-circle-outline'"
                  ></v-icon>
                </v-scale-transition>
              </v-row>
            </v-card-actions>
          </v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <v-expand-transition>
      <v-sheet
          v-if="selectedWasteGroup != null"
          min-height="400"
          tile
      >
        <div
            class="fill-height"
            align="center"
            justify="center"
        >
          <h3 class="text-h6">
            {{ groups[selectedWasteGroup].name }}
          </h3>
          <!-- <p>
            <v-img :src="groups[selectedWasteGroup].img" style="height: 200px;"></v-img>
          </p> -->
          <v-row>
            <v-col cols="12">
              <v-text-field label="Eigenbezeichnung" rounded filled></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-text-field label="Menge" rounded filled></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field label="Einheit" rounded filled></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
export default {
  name: "EasyWasteDefinition",
  data: () => ({
    selectedWasteGroup: null,
    groups: [
      {
        title: "Metalle",
        name: "Metallabfälle",
        img: "https://source.unsplash.com/wpOnFM9hjNI/640x853/",
        color: "red",
      },
      {
        title: "Kunststoff und Gummi",
        name: "Kunststoff- und Gummiabfälle",
        img: "https://source.unsplash.com/UKs_rzIYE6M/853x640/",
        color: "green",
      },
      {
        title: "Textilabfälle",
        name: "Textilabfälle",
        img: "https://source.unsplash.com/kgC99X3WH1w/640x853/",
        color: "purple",
      },
      {
        title: "Elektronik",
        name: "Elektro- und Elektronikabfälle",
        img: "https://source.unsplash.com/FO7JIlwjOtU/853x640/",
        color: "pink",
      },
      {
        title: "Holz",
        name: "Holzabfälle",
        img: "https://source.unsplash.com/xzPMUMDDsfk/853x640/",
        color: "orange",
      },
      {
        title: "Mineralische Abfälle",
        name: "Mineralische Abfälle",
        img: "https://source.unsplash.com/1nrY9CLAGcI/853x640/",
        color: "lime",
      },
      {
        title: "Aushubmaterialien",
        name: "Aushubmaterialien",
        img: "https://source.unsplash.com/9jPJrfLTBi0/853x640/",
        color: "orange",
      },
      {
        title: "Biogene Abfälle / Grünabfälle",
        name: "Biogene Abfälle / Grünabfälle",
        img: "https://source.unsplash.com/FFn2-TW8pxk/853x640/",
        color: "orange",
      },
      {
        title: "Mineralöl und Kohle",
        name: "Mineralöl- und Kohleabfälle",
        img: "https://source.unsplash.com/tm9Rt6BTMys/640x640/",
        color: "orange",
      },
      {
        title: "Medizinische Abfälle",
        name: "Medizinische Abfälle",
        img: "https://source.unsplash.com/pV5arhEZHiA/853x640/",
        color: "orange",
      },
      {
        title: "Chemikalien",
        name: "Chemikalienabfälle",
        img: "https://source.unsplash.com/H9t723yPjYI/853x640/",
        color: "orange",
      },
      {
        title: "sonstige nicht-gefährliche",
        name: "sonstige nicht-gefährliche Abfälle",
        img: "https://source.unsplash.com/qph7tJfcDys/853x640/",
        color: "orange",
      },
      {
        title: "sonstige gefährliche",
        name: "sonstige gefährliche Abfälle",
        img: "https://source.unsplash.com/QukrVl1F9Q0/853x640/",
        color: "orange",
      },
    ],
  }),
}
</script>

<style scoped>
.slider-content {
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}
.text-size-m {
  font-size: 1.0rem;
  overflow-wrap: normal !important;
}
</style>