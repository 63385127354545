<template>
<v-container>
  <v-card>
    <v-card-title>Offene Anfragen</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="leads"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
              icon
              class="mr-2"
              @click="buy(item)"
              outlined
          >
            <v-icon>mdi-cash-check</v-icon>
          </v-btn>
          <v-btn
              icon
              @click="rank(item)"
              outlined
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <v-card class="mt-5">
    <v-card-title>Mail</v-card-title>
    <v-card-text>
      <v-text-field
          label="Empfänger"
          v-model="recipient"
          outlined
      ></v-text-field>
      <v-text-field
          label="Betreff"
          v-model="subject"
          outlined
      ></v-text-field>
      <v-textarea label="Nachricht" v-model="message" outlined></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="send">Senden</v-btn>
    </v-card-actions>
  </v-card>
  <v-card class="mt-5">
    <v-card-title>Test flexible Json Controller</v-card-title>
    <v-card-text>
      <v-row v-for="data in jsonData" :key="data.id">
        <v-col>{{data.id}}</v-col>
        <v-col>{{data.user}}</v-col>
        <v-col>{{data.created}}</v-col>
        <v-col>{{data.jsonObject.message}}</v-col>
        <v-col>
          <v-btn icon outlined @click="deleteData(data.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field label="Nachricht" v-model="message" outlined class="mt-5"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="add">Add</v-btn>
      <v-btn icon  @click="getJsonData"><v-icon>mdi-sync</v-icon></v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import axios from "axios";
import JsonDataApi from "@/apis/JsonDataApi";

export default {
  name: "LeadOverview",
  data: () => ({
    headers: [
      {text: "SN", value: "sn"},
      {text: "Menge", value: "quantity"},
      {text: "Art", value: "type"},
      {text: "Aktionen", value: "actions"},
    ],
    leads: [
      {
        id: "1234",
        sn: "11002",
        quantity: "130 kg",
        type: "Spot"
      },
    ],
    recipient: "",
    subject: "",
    message: "",
    jsonData: [],
    jsonDataApi: null,
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
  },
  methods: {
    add() {
      this.jsonDataApi.add({
        message: this.message,
        test: "This is awesome",
        hasFields: true,
      }).then((res) => {
        console.log(res.data);
        this.getJsonData();
        this.message = "";
      }).catch((err)=>{
        console.log(err);
      });
    },
    getJsonData() {
      this.jsonDataApi.getAll().then((res)=>{
        this.jsonData = res.data;
        console.log(res.data);
      }).catch((err)=>{
        console.log(err);
      });
    },
    deleteData(id) {
      this.jsonDataApi.delete(id)
      .then(()=>{
        this.getJsonData()
      }).catch((err) => {
        console.log(err)
      })
    },
    buy(item) {
      console.log(item);
    },
    rank(item) {
      console.log(item);
    },
    send() {
      axios.post(this.baseUrl + "/sendmail", {
        to: this.recipient,
        subject: this.subject,
        message: this.message,
      })
      .then(()=>{
        console.log("all good");
      }).catch((err) => {
        console.log(err);
      })
    },
  },
  created() {
    this.jsonDataApi = new JsonDataApi();
    this.getJsonData();
  }
}
</script>

<style scoped>

</style>