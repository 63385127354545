<template>
<v-container>
  <v-card>
    <v-card-title>Kontakt</v-card-title>
    <v-card-text>
      <v-form
          ref="data"
          v-model="validData"
      >
        <v-select
            label="Art der Anfrage *"
            :items="types"
            v-model="type"
            :rules="[rules.required]"
            outlined
            rounded
        ></v-select>
        <v-textarea
            label="Nachricht *"
            v-model="message"
            :rules="[rules.required]"
            outlined
            rounded
        ></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" rounded :disabled="!validData || isSending" :loading="isSending" @click="sendMessage">Senden</v-btn>
    </v-card-actions>
  </v-card>
  <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      style="z-index: 10100;"
  >
    {{ snackbarMessage }}

    <template v-slot:action="{ attrs }">
      <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</v-container>
</template>

<script>
import {mapState} from "pinia";
import {wasticsStore} from "@/store";
import axios from "axios";

export default {
  name: "Contact",
  data: () => ({
    validData: false,
    rules: {
      required: value => !!value || 'Erforderlich',
    },

    types: [
        "Allgemeine Anfrage",
        "Plattformnutzung",
        "Partnerschaft",
        "Investment",
        "Fehlermeldung",
    ],
    type: "",
    message: "",
    isSending: false,

    snackbar: false,
    snackbarColor: "green",
    snackbarMessage: "",
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["business"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    sendMessage() {
      this.isSending = true;
      axios.post(this.baseUrl + "/v1/contact", {
        user: this.user,
        business: this.business,
        type: this.type,
        message: this.message,
      }, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then(()=>{
        this.resetForm();
      })
      .catch((err)=>{
        console.log(err)
      })
      setTimeout(()=>{this.resetForm()}, 2000);
    },
    resetForm() {
      this.isSending = false;
      this.type = "";
      this.message = "";

      this.$refs.data.reset();

      this.snackbarMessage = "Nachricht erfolgreich übermittelt. Vielen Dank."
      this.snackbarColor = "green";
      this.snackbar = true;
    },
  },
}
</script>

<style scoped>

</style>