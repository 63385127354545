<template>
<v-container>
  <v-card elevation="0">
    <v-card-title>Firmendaten</v-card-title>
    <v-card-text>
      <v-expansion-panels
          v-model="panel"
          multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header><h3>Stammdaten</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0">
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="Name" v-model="company.name" rounded filled readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Straße" v-model="company.street" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field label="Nummer" v-model="company.streetNo" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field label="Tür" v-model="company.door" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Adresse (optional)" v-model="company.additional" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="4" md="2">
                      <v-text-field label="PLZ" v-model="company.zip" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="8" md="5">
                      <v-text-field label="Ort" v-model="company.city" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-select label="Land" v-model="company.country" rounded filled :items="countries"></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Telefon" v-model="company.phone" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="E-Mail" v-model="company.mail" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Firmenbuch" v-model="company.fn" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="UID" v-model="company.uid" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="GLN" v-model="company.gln" rounded filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Webseite" v-model="company.url" rounded filled></v-text-field>
                    </v-col>
                    <v-col v-if="user.role === 'manager'">
                      <v-select
                          v-model="company.types"
                          :items="businessTypes"
                          label="Art"
                          outlined
                          multiple
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save" rounded>Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="developmentSystem">
          <v-expansion-panel-header><h3>Meine Abfallarten</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0">
              <v-card-text>
                <v-autocomplete label="SN / EWC" outlined></v-autocomplete>
                <v-text-field label="Eigenbezeichnung Abfallart" outlined></v-text-field>
                <v-row>
                  <v-col cols="8" md="4">
                    <v-text-field label="Losgröße min" outlined></v-text-field>
                  </v-col>
                  <v-col cols="4" md="2">
                    <v-select label="Einheit" :items="units" outlined></v-select>
                  </v-col>
                  <v-col cols="8" md="4">
                    <v-text-field label="Losgröße max" outlined></v-text-field>
                  </v-col>
                  <v-col cols="4" md="2">
                    <v-select label="Einheit" :items="units" outlined></v-select>
                  </v-col>
                </v-row>
                <v-select label="R/D-Verfahren" :items="rdItems" outlined></v-select>
                <v-select label="Standort" outlined multiple></v-select>
                <v-btn rounded outlined>Standort hinzufügen</v-btn>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded>hinzufügen</v-btn>
              </v-card-actions>
            </v-card>
            <v-card class="mt-5" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col col="6" md="3">
                    <v-card>
                      <v-card-text>
                        <v-btn icon color="primary"><v-icon>mdi-plus</v-icon></v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col col="6" md="3">
                    <v-card>
                      <v-card-text>
                        11022 - Eigenbezeichnung<br>
                        Losgröße: 300 kg bis 200 t<br>
                        R/D: Wiedergewinnung von Bla
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col col="6" md="3">
                    <v-card>
                      <v-card-text>
                        11022 - Eigenbezeichnung<br>
                        Losgröße: 300 kg bis 200 t<br>
                        R/D: Wiedergewinnung von Bla
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col col="6" md="3">
                    <v-card>
                      <v-card-text>
                        11022 - Eigenbezeichnung<br>
                        Losgröße: 300 kg bis 200 t<br>
                        R/D: Wiedergewinnung von Bla
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col col="6" md="3">
                    <v-card>
                      <v-card-text>
                        11022 - Eigenbezeichnung<br>
                        Losgröße: 300 kg bis 200 t<br>
                        R/D: Wiedergewinnung von Bla
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col col="6" md="3">
                    <v-card>
                      <v-card-text>
                        11022 - Eigenbezeichnung<br>
                        Losgröße: 300 kg bis 200 t<br>
                        R/D: Wiedergewinnung von Bla
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col col="6" md="3">
                    <v-card>
                      <v-card-text>
                        11022 - Eigenbezeichnung<br>
                        Losgröße: 300 kg bis 200 t<br>
                        R/D: Wiedergewinnung von Bla
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
  <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      style="z-index: 10100;"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</v-container>
</template>

<script>
import {mapState} from "pinia";
import {wasticsStore} from "@/store";
import axios from "axios";
import {mapActions} from "pinia";

export default {
  name: "Company",
  data: () => ({
    panel: [0],
    company: {},
    countries: [
        "Österreich",
        "Deutschland",
        "Schweiz",
    ],
    defaultCompany: {
      id: '',
      name: "",
      street: "",
      streetNo: "",
      additional: "",
      zip: "",
      city: "",
      country: "",
      fn: "",
      uid: "",
      gln: "",
      url: "https://",
      phone: "",
      mail: "",
      type: "",
      types: [],
    },
    businessTypes: [
      "WASTE_PRODUCER",
      "WASTE_COLLECTOR",
      "WASTE_DISPOSAL",
      "WASTE_CONSULTANT",
      "CONVERTER",
      "SUPPLIER",
      "TRANSPORT",
    ],
    rdItems: [
      {
        label: "R1",
        description: "Hauptverwendung als Brennstoff oder als anderes Mittel der Energieerzeugung",
      },
      {
        label: "R2",
        description: "Rückgewinnung/Regenerierung von Lösemitteln",
      },
      {
        label: "R3",
        description: "Recycling/Rückgewinnung organischer Stoffe, die nicht als Lösemittel verwendet werden (einschließlich der Kompostierung und sonstiger biologischer Umwandlungsverfahren)",
      },
      {
        label: "R4",
        description: "Recycling/Rückgewinnung von Metallen und Metallverbindungen",
      },
      {
        label: "R5",
        description: "Recycling/Rückgewinnung von anderen anorganischen Stoffen",
      },
      {
        label: "R6",
        description: "Regenerierung von Säuren und Basen",
      },
      {
        label: "R7",
        description: "Wiedergewinnung von Bestandteilen, die der Bekämpfung der Verunreinigungen dienen",
      },
      {
        label: "R8",
        description: "Wiedergewinnung von Katalysatorenbestandteilen",
      },
      {
        label: "R9",
        description: "Erneute Ölraffination oder andere Wiederverwendungen von Öl",
      },
      {
        label: "R10",
        description: "Aufbringung auf den Boden zum Nutzen der Landwirtschaft oder zur ökologischen Verbesserung",
      },
      {
        label: "R11",
        description: "Verwendung von Abfällen, die bei einem der unter R1 bis R10 aufgeführten Verfahren gewonnen werden",
      },
      {
        label: "R12",
        description: "Austausch von Abfällen, um sie einem der unter R1 bis R11 aufgeführten Verfahren zu unterziehen",
      },
      {
        label: "R13",
        description: "Lagerung von Abfällen bis zur Anwendung eines der unter R1 bis R12 aufgeführten Verfahren (ausgenommen zeitweilige Lagerung – bis zur Sammlung – auf dem Gelände der Entstehung der Abfälle)",
      },
    ],
    units: [
      "kg", "t", "m³", "l", "Stück"
    ],
    message: "",
    snackbar: false,
    snackbarColor: "red",
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    developmentSystem() {
      if(window.location.hostname === "localhost") {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    save() {
      this.message = "Business speichern"
      if(this.user.business !== null) {
        this.updateCompany();
      } else {
        this.addCompany();
      }
    },
    addCompany() {
      axios.post(this.baseUrl + "/v1/companies", this.company, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res)=>{
            this.message = "Daten gespeichert";
            this.snackbarColor = "green";
            this.company = res.data;
            this.updateUserdata();
            this.snackbar = true;
          })
          .catch(()=>{
            this.message = "Daten konnten nicht gespeichert werden";
            this.snackbarColor = "red";
            this.snackbar = true;
          });
    },
    updateCompany() {
      axios.put(this.baseUrl + "/v1/companies", this.company, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res)=>{
            this.message = "Daten aktualisiert";
            this.snackbarColor = "green";
            this.company = res.data;
            this.snackbar = true;
          })
          .catch(()=>{
            this.message = "Daten konnten nicht aktualisiert werden";
            this.snackbarColor = "red";
            this.snackbar = true;
          });
    },
    deleteCompany() {},
    getCompany() {
      if(this.user.business !== null) {
        axios.get(this.baseUrl + "/v1/companies/" + this.user.business, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
            .then((res) => {
              this.company = Object.assign({}, res.data);
              this.message = "Firmendaten wurden geladen"
              this.snackbarColor = "green";
              this.snackbar = true;
              console.log(this.company);
            })
            .catch(() => {
              this.message = "Firmendaten konnten nicht geladen werden";
              this.snackbarColor = "red";
              this.snackbar = true;
            })
      } else {
        this.company = Object.assign({}, this.defaultCompany);
      }
    },
    ...mapActions(wasticsStore, ["updateUserdata"]),
  },
  created() {
    this.getCompany();
  }
}
</script>

<style scoped>

</style>