import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#6A1B9A',
                secondary: '#E1BEE7',
                accent: '#E91E63',
            }
        }
    },
    lang: {
        locales: [de, en],
        current: "de"
    }
});
