<template>
<v-container>
  <v-card>
    <div id="mapContainer" style="height: 50vh; width: 100%;"></div>
    <v-card-actions>
      <v-btn @click="setMarker">Add Marker</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-text-field v-model="street" label="Straße" outlined></v-text-field>
      <v-text-field v-model="city" label="Stadt" outlined></v-text-field>
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="lat" disabled outlined label="Lat"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="lng" disabled outlined label="Lng"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="getLatLon">Test</v-btn>
      <v-btn @click="logLayers">Marker löschen</v-btn>
      <v-btn @click="getLocation">Aktuelle Position</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {Icon} from "leaflet";
import L from "leaflet";
import axios from "axios";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: "Maptest",
  data: () => ({
    test: false,
    location: [48.1767414, 16.3313324],
    map: null,
    street: "",
    city: "",
    marker: null,
    lat: 48.1767414,
    lng: 16.3313324,
    position: null,
  }),
  methods: {
    logLayers() {
      this.map.eachLayer((layer) => {
        console.log(layer);
        if(layer._icon) {
          layer.remove();
        }
      });
    },
    setupLeaflet() {
        this.map = L.map("mapContainer").setView(this.location, 18);
        L.tileLayer(
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            {
              attribution: 'Wastics',
              maxZoom: 19,
            }
        ).addTo(this.map);
        this.map.on('click', this.onMapClick);
    },
    setMarker() {
      if(this.marker != null) {
        this.marker.remove();
      }
      this.marker = L.marker(this.location).addTo(this.map);
    },
    getLatLon() {
      axios.get("https://nominatim.openstreetmap.org/search?format=json&street="+ encodeURIComponent(this.street) + "&city="+ encodeURIComponent(this.city))
          .then((res)=>{
            console.log(res.data);
            this.location = [];
            this.location.push(res.data[0].lat);
            this.location.push(res.data[0].lon);
            this.lat = res.data[0].lat;
            this.lng = res.data[0].lon;
            if(this.marker != null) {
              this.marker.remove();
            }
            this.setMarker();
            this.map.setView(this.location, 18);
          })
    },
    onMapClick(e) {
      console.log(e.latlng);
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + e.latlng.lat + "&lon=" + e.latlng.lng + "&zoom=18&addressdetails=1")
      .then((res)=>{
        console.log(res.data);
        if(res.data.address.city) {
          this.city = res.data.address.city;
        } else {
          if(res.data.address.town) {
            this.city = res.data.address.town;
          } else {
            this.city = res.data.address.state;
          }
        }
        if (res.data.address.house_number) {
          this.street = res.data.address.road + ' ' + res.data.address.house_number;
        } else {
          this.street = res.data.address.road;
        }
        this.location = [];
        this.location.push(e.latlng.lat);
        this.location.push(e.latlng.lng);
        this.lat = e.latlng.lat;
        this.lng = e.latlng.lng;
        if(this.marker != null) {
          this.marker.remove();
        }
        this.setMarker();
      })
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.savePosition, this.positionError, {timeout:10000});
      } else {
        console.log("not supported gls");
      }
    },
    positionError(error) {
      console.log(error.code);
      console.log(error.message);
    },
    savePosition(position) {
      console.log(position);
      this.position = position;
      this.location = [];
      this.location.push(position.coords.latitude);
      this.location.push(position.coords.longitude);
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.setMarker();
      this.map.setView(this.location, 18);
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + this.lat + "&lon=" + this.lng + "&zoom=18&addressdetails=1")
          .then((res)=> {
            console.log(res.data);
            if (res.data.address.city) {
              this.city = res.data.address.city;
            } else {
              if (res.data.address.town) {
                this.city = res.data.address.town;
              } else {
                this.city = res.data.address.state;
              }
            }
            if (res.data.address.house_number) {
              this.street = res.data.address.road + ' ' + res.data.address.house_number;
            } else {
              this.street = res.data.address.road;
            }
          });
    },
  },
  mounted() {
    this.setupLeaflet();
    this.getLocation();
  }
}
</script>

<style>
.leaflet-control-attribution {
  display: none !important;
}
</style>