<template>
<v-container>
  <v-card>
    <v-card-title>Media Manager</v-card-title>
    <v-card-text>
      <v-text-field v-model="street" label="Straße"></v-text-field>
      <v-text-field v-model="city" label="Stadt"></v-text-field>
      <v-text-field v-model="lat" label="LAT"></v-text-field>
      <v-text-field v-model="lon" label="LON"></v-text-field>
    </v-card-text>
    <v-carc-actions>
      <v-btn @click="getLatLon">Test</v-btn>
    </v-carc-actions>
    <v-card-text>
      <v-switch v-model="listView" label="Liste"></v-switch>
    </v-card-text>
    <v-card-text v-if="!listView">
      <p v-if="error != ''">{{error}}</p>
      <v-row>
        <v-col cols="12" sm="6" md="4" v-for="upload in uploads" :key="upload.id">
          <v-sheet>
            <v-img v-if="upload.thumbnail !== null" :src="baseUrl + '/v1/thumbnail/' + upload.id"></v-img>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-row v-for="upload in uploads" :key="upload.id">
        <v-col cols="12" sm="3">
          <v-sheet>
            <v-img v-if="upload.thumbnail !== null" :src="baseUrl + '/v1/thumbnail/' + upload.id"></v-img>
          </v-sheet>
        </v-col>
        <v-col>
          <span>{{upload.originalFilename}}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "MediaManager",
  data: () => ({
    uploads: [],
    error: "",
    listView: false,
    street: "",
    city: "",
    lat: "",
    lon: "",
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
  },
  methods: {
    getLatLon() {
      axios.get("https://nominatim.openstreetmap.org/search?format=json&street="+ encodeURIComponent(this.street) + "&city="+ encodeURIComponent(this.city))
      .then((res)=>{
        console.log(res.data);
        this.lat = res.data[0].lat;
        this.lon = res.data[0].lon;
      })
    },
    getUploads() {
      axios.get(this.baseUrl + "/v1/uploads")
          .then((res)=>{
            this.uploads = res.data;
            console.log(res.data);
          })
          .catch(()=>{
            this.error = "Fehler beim Abruf"
          });
    },
  },
  created() {
    this.getUploads();
  }
}
</script>

<style scoped>

</style>