<template>
<v-container>
  <v-card class="pb-5">
    <v-card-title>Mein Profil</v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Vorname"
                v-model="user.firstname"
                disabled
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Nachname"
                v-model="user.lastname"
                disabled
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Email"
                v-model="user.email"
                disabled
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Telefon"
                v-model="user.phone"
                disabled
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn icon @click="refresh"><v-icon>mdi-sync</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="deleteCompany2" v-if="user.email == 'rene@wastics.eu'">Firmendaten löschen 2</v-btn>
      <v-btn @click="deleteCompany" v-if="user.email == 'rene@wastics.eu'">Firmendaten löschen</v-btn>
      <v-btn disabled color="primary" rounded>Aktualisieren</v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" x-large @click="redirectUpdatePassword">Passwort ändern</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import {mapState} from "pinia";
import {wasticsStore} from "@/store";
import axios from "axios";
import {mapActions} from "pinia/dist/pinia";

export default {
  name: "Profile",
  data: () => ({

  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
  methods: {
    deleteCompany() {
      axios.delete(this.baseUrl + "/v1/companies/" + this.user.business, {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
      .then(() => {
        console.log("Firmendaten gelöscht");
        this.updateUserdata();
      })
      .catch(()=>{
        console.log("Firmendaten konnten nicht gelöscht werden");
      })
    },
    deleteCompany2() {
      axios.delete(this.baseUrl + "/v1/companies2", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then(() => {
            console.log("Firmendaten gelöscht");
            this.updateUserdata();
          })
          .catch(()=>{
            console.log("Firmendaten konnten nicht gelöscht werden");
          })
    },
    redirectUpdatePassword() {
      let url = "https://auth.wastics.eu/realms/Wastics/protocol/openid-connect/auth?client_id=prototype-app&redirect_uri=https%3A%2F%2Fapp0.wastics.eu%2Fprofile&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD";

      if(window.location.hostname === "localhost") {
        url = "http://localhost:9000/realms/Wastics/protocol/openid-connect/auth?client_id=prototype-app&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fprofile&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD";
      }

      window.location.href = url;
    },
    refresh() {
      this.updateUserdata();
    },
    ...mapActions(wasticsStore, ["updateUserdata"]),
  },
}
</script>

<style scoped>

</style>