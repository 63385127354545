<template>
<v-container>
  <v-row>
    <v-col cols="4">
      <v-card>
        <v-card-title>Entsorgen</v-card-title>
        <v-card-text>Einmalige Entsorgungsanfrage stellen</v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card>
        <v-card-title>Rahmenvertrag suchen</v-card-title>
        <v-card-text>Du suchst nach einer langfristigen Entsorgungslösung?</v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card>
        <v-card-title>Registrieren</v-card-title>
        <v-card-text>Nutze die Vorteile des digitalen Wertstoffmanagements und behalte den Überblick über deine Aufträge und Dokumente</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>