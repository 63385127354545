<template>
<v-container>
  <v-card>
    <v-card-title>Nicht gefunden</v-card-title>
    <v-card-text>
      Die von dir aufgerufene Seite konnte nicht gefunden werden.
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>