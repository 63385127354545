<template>
<v-container>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Auftragsübersicht</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/" color="primary">Neue Anfrage</v-btn>
    </v-toolbar>
    <v-card-text v-if="orders.length == 0">Aktuell sind keine Daten vorhanden</v-card-text>
    <v-card-text v-else>
      <v-expansion-panels>
        <v-expansion-panel
            v-for="order in orders"
            :key="order.id"
        >
          <v-expansion-panel-header>
            {{order.created | orderDate}} {{order.wasteRequests.length}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-for="waste in order.wasteRequests" :key="waste.id">
              Stoff: {{waste.SN}} {{waste.description}}<br>
              Menge: {{waste.quantity}} {{waste.unit}}<br>
              Ort: {{waste.pickupLocation.zip}} {{waste.pickupLocation.city}}, {{waste.pickupLocation.street}}<br>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "axios";
import {mapState} from "pinia/dist/pinia";
import {wasticsStore} from "@/store";

export default {
  name: "Order",
  data: () => ({
    orders: [
      {
        id: '0',
        created: "2022-04-22T15:00:00",
        customerData: null,
        deleted: "",
        wasteRequests: [],
        waste: {
          created: "2022-04-22T15:00:00",
          description: "Holzspäne",
          SN: "",
          quantity: "580",
          unit: "kg",
          pickupLocation: {
            street: "Webgasse 43/2",
            zip: "1060",
            city: "Wien"
          },
        },
        state: 'created',
      }
    ],
  }),
  filters: {
    orderDate(value) {
      if (!value) return ''
      value = value.toString()
      let date = new Date(value);
      return new Intl.DateTimeFormat('de-DE', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date);
    },
  },
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["loggedIn"]),
    ...mapState(wasticsStore, ["token"]),
    ...mapState(wasticsStore, ["payload"]),
    ...mapState(wasticsStore, ["keycloak"]),
  },
  methods: {
    getOrders() {
      axios
          .get(this.baseUrl + "/v1/orders", {headers: {"Authorization" : `Bearer ${this.keycloak.token}`}})
          .then((res) => {
            this.orders = res.data;
            console.log(this.orders);
            this.orders.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));
            this.orders.reverse();
          });
    },
  },
  created() {
    this.getOrders();
  },
}
</script>

<style scoped>

</style>